import * as Icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, ButtonGroup, Form, Table } from "react-bootstrap";
import AppContext from './AppContext';
import * as IDB from "../indexedDB";

class IDBTable extends React.Component {

    static contextType = AppContext;

    timeout = null;

    state = {
        items: [],
        limit: 10,
        offset: 0,
        filter: ''
    }

    update() {
        const { limit, offset, filter } = this.state;
        const { headers } = this.props;

        IDB.getAll(this.props.storeName).then(items => {
            if (items.length) {
                const filteredItems = items
                    .filter(item =>
                        !filter || headers.some(header =>
                            item[header]?.toString().includes(filter)
                        )
                    )
                const slicedAndFilteredItems = filteredItems.slice(limit ? offset : 0, limit ? offset + limit : undefined);

                this.setState({ items: slicedAndFilteredItems });
            } else {
                this.setState({ items: [] });
            }
        });
    }

    componentDidMount() {
        this.update();
    }

    scheduleUpdate() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => this.update(), 1000);
    }

    render() {

        const { items, limit, offset, filter } = this.state;
        const { headers } = this.props;

        const page = (limit ? offset / limit : 0) + 1;
        const tools = <tr>
            <td colSpan={headers.length + 1} className="">
                <Form onSubmit={event => this.search(event)} className="d-flex flex-wrap">
                    {this.props.onAdd &&
                        <Button size="sm" variant="success" className="text-nowrap flex-fill m-1" onClick={() => this.props.onAdd(() => this.update())}>
                            <FontAwesomeIcon icon={Icons.faPlus} /> Adicionar
                        </Button>}
                    <Form.Control size="sm" className="flex-fill flex-nowrap w-auto m-1" placeholder="Pesquisar..." value={filter} onChange={e => this.setState({ filter: e.target.value }, () => this.scheduleUpdate())} />
                    <Form.Control size="sm" as="select" className="w-auto flex-fill m-1" value={limit} onChange={e => this.setState({ limit: parseInt(e.target.value), offset: 0 }, () => this.update())}>
                        <option value={10}>10 registros</option>
                        <option value={20}>20 registros</option>
                        <option value={50}>50 registros</option>
                        <option value={100}>100 registros</option>
                        <option value={0}>Todos os registros</option>
                    </Form.Control>
                    <ButtonGroup className="d-flex flex-nowrap w-auto flex-fill m-1">
                        <Button size="sm" variant="outline-success" disabled={page === 1} onClick={() => this.setState(state => ({ offset: state.offset - state.limit }), () => this.update())}>
                            <FontAwesomeIcon icon={Icons.faBackward} />
                        </Button>
                        <Button size="sm" variant="outline-success" className="flex-grow-1">Página {page}</Button>
                        <Button size="sm" variant="outline-success" disabled={items.length === 0 || limit === 0}>
                            <FontAwesomeIcon icon={Icons.faForward} onClick={() => this.setState(state => ({ offset: state.offset + state.limit }), () => this.update())} />
                        </Button>
                    </ButtonGroup>
                </Form>
            </td>
        </tr>;

        return <Table responsive>
            <thead>
                <tr>
                    {headers.map((header, key) =>
                        <th key={key}>
                            {header.replace(/\b\w/g, match => match.toUpperCase())}
                        </th>)}
                    <th className="text-center" style={{ width: '1%' }}>
                        Ações
                    </th>
                </tr>
            </thead>
            <tbody>
                {items.length > 0 ?
                    items.map((item, key) => <tr key={key}>
                        {headers.map((header, key) =>
                            <td key={key} style={{ verticalAlign: "middle" }}>
                                {item[header]}
                            </td>)}
                        <td className="text-center text-nowrap" style={{ width: '1%' }}>
                            {this.props.onActionEdit &&
                                <Button size="sm" title="Editar" className="mx-1" variant="outline-primary" onClick={() => this.props.onActionEdit(item, () => this.update())}>
                                    <FontAwesomeIcon icon={Icons.faEdit} />
                                </Button>}
                            {this.props.onActionSync &&
                                <Button size="sm" title="Sincronizar" className="mx-1" variant="outline-info" onClick={() => this.props.onActionSync(item, () => this.update())}>
                                    <FontAwesomeIcon icon={Icons.faSync} />
                                </Button>}
                            {this.props.onActionDelete &&
                                <Button size="sm" title="Excluir" className="mx-1" variant="outline-danger" onClick={() => this.props.onActionDelete(item, () => this.update())}>
                                    <FontAwesomeIcon icon={Icons.faTrash} />
                                </Button>}
                        </td>
                    </tr>) :
                    <tr>
                        <td colSpan={headers.length + 1} className="text-center">
                            Nenhum resultado encontrado.
                        </td>
                    </tr>}
            </tbody>
            <tfoot>
                {tools}
            </tfoot>
        </Table>;
    }
}

export default IDBTable;