import React from "react";
import AppContext from "../AppContext";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { getTileUrls } from "../../mapcache";
import MapWithMoveCallback from "../MapWithMoveCallback";
import ReactSlider from 'rc-slider';
import * as IDB from "../../indexedDB";

class MapCacheForm extends React.Component {

    static contextType = AppContext;

    state = {
        nome: "",
        zoom: 15,
        latitude: -1.48,
        longitude: -48,
        maxLat: -1.48,
        maxLng: -48,
        minLat: -1.48,
        minLng: -48,    
        ...this.props.item,
    };

    submit(event) {
        event.preventDefault();

        //Deletar o cache atual
        caches.open('osm-tiles-cache-v1')
            .then(async cache => {

                if (this.props.urls) {
                    await Promise.all(this.props.urls.map(url => cache.delete(url)));
                }

                const { minLat, maxLat, minLng, maxLng, zoom } = this.state;

                const urls = getTileUrls(
                    "https://a.tile.openstreetmap.org/{z}/{x}/{y}.png",
                    { minLat, maxLat, minLng, maxLng, zoom }
                );

                IDB.put("MapCache", { ...this.state, urls }).then(() => {
                    navigator.serviceWorker?.controller?.postMessage({ type: 'ADD_MAPCACHE', urls });
                    this.props.onSave();
                }).catch(error => console.log(error));
            })
            .catch((error) => {
                console.error('Cache put error:', error);
            });

    }

    onMapChange(bounds) {
        console.log(bounds);
        this.setState(bounds);
    }

    componentDidMount() {
        this.setState(state => ({ complete: true}));
    }

    render() {

        const { nome, zoom } = this.state;

        return <Form onSubmit={event => this.submit(event)}>
            <Form.Group className="my-2">
                <Form.Label>Nome</Form.Label>
                <Form.Control required value={nome} onChange={e => this.setState({ nome: e.target.value })} />
            </Form.Group>
            <Form.Group className="my-2">
                <Form.Label>Zoom ({zoom} a {Math.min(zoom + 4, 20)})</Form.Label>
                <ReactSlider value={zoom} min={0} max={20} step={1} onChange={zoom => this.setState({zoom})} />
            </Form.Group>

            <MapWithMoveCallback mutable={this.state.complete} zoom={zoom} bounds={this.state} onMapChange={bounds => this.onMapChange(bounds)} />

            <Form.Group className="mt-2 d-flex justify-content-between">
                <Button variant="secondary" onClick={() => this.context.closeModal()}>
                    <FontAwesomeIcon icon={faTimes} /> Cancelar
                </Button>
                <Button type="submit" variant="primary">
                    <FontAwesomeIcon icon={faSave} /> Gravar
                </Button>
            </Form.Group>
        </Form>
    }
}

export default MapCacheForm;