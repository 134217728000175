const { versions } = require("./tables");

const { REACT_APP_IDB_VERSION, REACT_APP_IDB_NAME } = process.env;

console.log(process.env);

if (!REACT_APP_IDB_NAME || !REACT_APP_IDB_VERSION) {
    throw new Error("As variáveis de ambiente REACT_APP_IDB_NAME e REACT_APP_IDB_VERSION não foram definidas.");
}

const myReject = (error, reject) => {
    console.log(error);
    //return reject(error);
    if (window.confirm("É necessário atualizar a versão do banco de dados. Isto apagará todos os rascunhos e cache de mapas. Deseja prosseguir?")) {

        const request1 = indexedDB.open(REACT_APP_IDB_NAME);

        request1.onsuccess = event => {

            const db = event.target.result;
            db.close();

            const request2 = indexedDB.deleteDatabase(REACT_APP_IDB_NAME);
            request2.onsuccess = () => {
                window.location.reload(true);
            };
            request2.onerror = event => {
                reject(new Error("Erro ao atualizar o Banco de Dados: " + event.target.error))};
        }

        request1.onerror = event => reject(new Error("Erro ao atualizar o Banco de Dados: " + event.target.error));

    } else {
        reject(error);
    }
}

// Retorna uma promise que resolve com a conexão com o indexed DB.
export function open() {
    return new Promise((resolve, reject) => {
        try {
            // Define a requisição de abertura do IDB, a partir das variáveis de ambiente.
            const request = indexedDB.open(REACT_APP_IDB_NAME, REACT_APP_IDB_VERSION);

            // Define o que deve ser feito caso seja necessário um upgrade da estrutura do DB.
            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                const oldVersion = event.oldVersion;
                var upgradeStores = [];

                // Para cada tabela definida em cada versão do banco, aplicar as alterações, caso ainda não tenham sido feitas.
                versions.forEach((stores, version) => {
                    if (oldVersion < version + 1)
                        upgradeStores = [...upgradeStores, ...stores];
                });

                // Cria os índices nas tabelas definidas no passo anterior
                upgradeStores.forEach(store => {
                    db.createObjectStore(store.storeName, { keyPath: store.keyPath, autoIncrement: store.autoIncrement });
                });

                window.alert("Atualização do banco de dados realizada com sucesso.");
            };

            // Em caso de sucesso, resolve a promise com a conexão.
            request.onsuccess = (event) => {
                resolve(event.target.result);
            };

            // Caso contrário, rejeita a promise e loga o erro no console.
            request.onerror = (event) => {
                console.error(event);
                myReject(new Error('Erro ao abrir o banco de dados.'), reject);
            };
        } catch (error) {
            myReject(error, reject);
        }
    });
}

// Retorna uma promise que resolve com todas as entradas de um store
export function getAll(storeName) {
    return new Promise((resolve, reject) => {
        open().then((db) => {
            const transaction = db.transaction([storeName], 'readonly');
            const store = transaction.objectStore(storeName);
            const request = store.getAll();
            request.onsuccess = (event) => {
                resolve(event.target.result);
            };
            request.onerror = (event) => {
                myReject(event.target.error, reject);
            }
        }).catch(error => myReject(error, reject));
    });
}

// Retorna uma promise que executa uma alteração em um registro da store e resolve em caso de sucesso.
export function put(storeName, entry) {
    return new Promise((resolve, reject) => {
        open().then((db) => {
            const transaction = db.transaction([storeName], 'readwrite');
            const store = transaction.objectStore(storeName);
            const request = store.put(entry);
            request.onsuccess = (event) => {
                resolve(event.target.result);
            };
            request.onerror = (event) => {
                myReject(event.target.error, reject);
            }
        }).catch(error => myReject(error, reject));
    });
}

// Retorna uma promise que altera vários registros no DB e resolve em caso de sucesso
export function putAll(storeName, entries) {
    return new Promise((resolve, reject) => {
        open().then((db) => {
            const transaction = db.transaction([storeName], 'readwrite');
            const store = transaction.objectStore(storeName);
            Promise.all(entries.map(entry => new Promise((resolve, reject) => {
                const request = store.put(entry);
                request.onsuccess = (event) => {
                    resolve(event.target.result);
                };
                request.onerror = (event) => {
                    myReject(event.target.error, reject);
                }
            })))
                .then(resolve)
                .catch(error => myReject(error, reject));
        }).catch(error => myReject(error, reject));
    });
}

// Retorna uma promise que resolve com o valor de um registro da store, armazenado sob o índice indicado
export function get(storeName, index) {
    return new Promise((resolve, reject) => {
        open().then((db) => {
            const transaction = db.transaction([storeName], 'readonly');
            const store = transaction.objectStore(storeName);
            const request = store.get(index);
            request.onsuccess = (event) => {
                resolve(event.target.result);
            };
            request.onerror = (event) => {
                myReject(event.target.error, reject);
            }
        }).catch(error => myReject(error, reject));
    });
}

// Retorna uma promise que insere um novo registro no DB e resolve com o índice do novo registro criado.
export function add(storeName, entry) {
    return new Promise((resolve, reject) => {
        open().then((db) => {
            const transaction = db.transaction([storeName], 'readwrite');
            const store = transaction.objectStore(storeName);
            const request = store.add(entry);

            request.onsuccess = (event) => {
                resolve(event.target.result);
            };
            request.onerror = (event) => {
                myReject(event.target.error, reject);
            }
        }).catch(error => myReject(error, reject));
    });
}

// Retorna uma promise que insere vários registros no DB e resolve com os índice criados
export function addAll(storeName, entries) {
    return new Promise((resolve, reject) => {
        open().then((db) => {
            const transaction = db.transaction([storeName], 'readwrite');
            const store = transaction.objectStore(storeName);
            Promise.all(entries.map(entry => new Promise((resolve, reject) => {
                const request = store.add(entry);
                request.onsuccess = (event) => {
                    resolve(event.target.result);
                };
                request.onerror = (event) => {
                    myReject(event.target.error, reject);
                }
            })))
                .then(resolve)
                .catch(error => myReject(error, reject));
        }).catch(error => myReject(error, reject));
    });
}

// Retorna uma promise que remove um registro de uma store com o indice definido.
export function remove(storeName, index) {
    return new Promise((resolve, reject) => {
        open().then((db) => {
            const transaction = db.transaction([storeName], 'readwrite');
            const store = transaction.objectStore(storeName);
            const request = store.delete(index);
            request.onsuccess = (event) => {
                resolve(event.target.result);
            };
            request.onerror = (event) => {
                myReject(event.target.error, reject);
            }
        }).catch(error => myReject(error, reject));
    });
}

// Retorna uma promise que limpa todas as entradas de um store.
export function clear(storeName) {
    return new Promise((resolve, reject) => {
        open().then((db) => {
            const transaction = db.transaction([storeName], 'readwrite');
            const store = transaction.objectStore(storeName);
            const request = store.clear();
            request.onsuccess = (event) => {
                resolve(event.target.result);
            };
            request.onerror = (event) => {
                myReject(event.target.error, reject);
            }
        }).catch(error => myReject(error, reject));
    });
}

// Retorna uma promise que limpa os registros de todas as stores do DB.
export function clearAll() {
    return new Promise((resolve, reject) => {
        open().then((db) => {
            const storeNames = Array.from(db.objectStoreNames);
            Promise.all(storeNames.map(storeName => clear(storeName)))
                .then(resolve)
                .catch(error => myReject(error, reject));
        }).catch(error => myReject(error, reject));
    });
}
