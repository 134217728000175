import { Component } from "react";
import AppContext from "./AppContext";
import { ListGroup, ListGroupItem, Offcanvas } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs, faGlobe, faHelmetSafety, faLayerGroup, faMapMarkedAlt, faMasksTheater, faMicroscope, faSearchLocation, faSignOut, faSync, faTrowel, faUsers } from "@fortawesome/free-solid-svg-icons";
import SitioList from "./sitio/SitioList";
import AuxiliarList from "./admin/AuxiliarList";
import MapCacheList from "./admin/MapCacheList";
import UsuarioList from "./admin/UsuarioList";

class OffcanvasMenu extends Component {

    static contextType = AppContext;

    render() {
        return <Offcanvas className="menu" placement="end" show={this.context.menu} onHide={() => this.context.hideMenu()}>
            <Offcanvas.Header closeButton className="d-flex align-items-start">
                <h4 className="mb-2 text-center">Sistema Tecnológico de Arqueologia</h4>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column mt-0 pt-0">
                <div className="text-center small d-flex flex-column align-items-center mb-3">
                    <div>{this.context.usuario?.nome} - {this.context.usuario?.perfil}</div>
                    <div>{this.context.usuario?.email}</div>
                </div>
                <ListGroup className="mb-3">
                    <ListGroupItem role="button" onClick={() => this.context.setContent(<SitioList />)}>
                        <FontAwesomeIcon icon={faMapMarkedAlt} size="1x" />&nbsp;Sítios
                    </ListGroupItem>
                    <ListGroupItem disabled role="button">
                        <FontAwesomeIcon icon={faTrowel} size="1x" />&nbsp;Escavações
                    </ListGroupItem>
                    <ListGroupItem disabled role="button">
                        <FontAwesomeIcon icon={faSearchLocation} size="1x" />&nbsp;Vistorias
                    </ListGroupItem>
                    <ListGroupItem disabled role="button">
                        <FontAwesomeIcon icon={faHelmetSafety} size="1x" />&nbsp;Tradagens
                    </ListGroupItem>
                    <ListGroupItem disabled role="button">
                        <FontAwesomeIcon icon={faLayerGroup} size="1x" />&nbsp;Perfis
                    </ListGroupItem>
                    <ListGroupItem disabled role="button">
                        <FontAwesomeIcon icon={faMasksTheater} size="1x" />&nbsp;Imaterial
                    </ListGroupItem>
                    <ListGroupItem disabled role="button">
                        <FontAwesomeIcon icon={faMicroscope} size="1x" />&nbsp;Laboratório
                    </ListGroupItem>
                    {this.context.usuario?.perfil === "Administrador"? <>
                    <ListGroupItem disabled={!this.context.alive} role="button" onClick={() => this.context.setContent(<UsuarioList />)}>
                        <FontAwesomeIcon icon={faUsers} size="1x" />&nbsp;Usuários
                    </ListGroupItem>                                        
                    <ListGroupItem disabled={!this.context.alive} role="button" onClick={() => this.context.setContent(<AuxiliarList />)}>
                        <FontAwesomeIcon icon={faCogs} size="1x" />&nbsp;Configurações
                    </ListGroupItem>
                    </> : null}
                    <ListGroupItem disabled={!this.context.alive} role="button" onClick={() => this.context.sync()}>
                        <FontAwesomeIcon icon={faSync} size="1x" spin={this.context.syncing} />&nbsp;Sincronizar
                    </ListGroupItem> 
                    <ListGroupItem role="button" onClick={() => this.context.setContent(<MapCacheList />)}>
                        <FontAwesomeIcon icon={faGlobe} size="1x" />&nbsp;Cache de Mapas
                    </ListGroupItem>                                       
                    <ListGroupItem className="text-danger" role="button" onClick={() => this.context.logoff()}>
                        <FontAwesomeIcon icon={faSignOut} size="1x" />&nbsp;Sair
                    </ListGroupItem>
                </ListGroup>
                <div className="text-center mt-auto">
                    Desenvolvido por Agile ® 2023<br />Versão {process.env.REACT_APP_VERSION ?? '1.0.0'}
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    }
}

export default OffcanvasMenu;