import React from "react";
import AppContext from "../AppContext";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";

class AuxiliarForm extends React.Component {

    static contextType = AppContext;

    state = {
        ...(this.props.auxiliar ?? {})
    };

    submit(event) {
        event.preventDefault();

        const id = this.state.id ?? '';

        const method = id ? "PUT" : "POST";
        const url = `/auxiliar/${this.props.tabela.nome}/${id}`

        this.context.request(method, url, this.state)
            .then(() => {
                this.context.addToast({
                    header: "Sucesso",
                    body: "Alterações gravadas com sucesso!"
                });
                this.props.onSave();
            });
    }

    render() {

        return <Form onSubmit={event => this.submit(event)}>
            {this.props.tabela.campos.map((campo, key) =>
                <Form.Group className="mt-2" key={key}>
                    <Form.Label>{campo.titulo}</Form.Label>
                    <Form.Control required value={this.state[campo.nome]} onChange={e => this.setState({ [campo.nome]: e.target.value })} />
                </Form.Group>
            )}
            <Form.Group className="mt-2 d-flex justify-content-between">
                <Button variant="secondary" onClick={() => this.context.closeModal()}>
                    <FontAwesomeIcon icon={faTimes} /> Cancelar
                </Button>
                <Button type="submit" variant="primary">
                    <FontAwesomeIcon icon={faSave} /> Gravar
                </Button>
            </Form.Group>
        </Form>
    }
}

export default AuxiliarForm;