// Defina as coordenadas do retângulo e os níveis de zoom
export function getTileUrls(url, bounds) {

    const tiles = tilesFromBoundingBox(bounds);

    return tiles.map(tile =>
        url.replace("{x}", tile.x).replace("{y}", tile.y).replace("{z}", tile.z)
    );

}

function latLonToTile(lat, lon, zoom) {
    const n = Math.pow(2, zoom);
    const xTile = ((lon % 180 + 180) / 360) * n;
    const yTile = ((1 - Math.log(Math.tan(lat * (Math.PI / 180)) + 1 / Math.cos(lat * (Math.PI / 180))) / Math.PI) / 2) * n;
    return { x: Math.floor(xTile), y: Math.floor(yTile) };
}

function tilesFromBoundingBox({ minLat, maxLat, minLng, maxLng, zoom}) {

    const tiles = [];

    for (let z = zoom; z <= Math.min(20, zoom + 4); z++) {
        const minTile = latLonToTile(minLat, minLng, z);
        const maxTile = latLonToTile(maxLat, maxLng, z);
        for (let x = Math.min(minTile.x, maxTile.x); x <= Math.max(maxTile.x, minTile.x); x++) {
            for (let y = Math.min(minTile.y, maxTile.y); y <= Math.max(maxTile.y, minTile.y); y++) {
                tiles.push({ x, y, z });
            }
        }
    }

    return tiles;
}

export function cacheCount() {
    return caches.open('osm-tiles-cache-v1')
        .then((cache) => cache.keys())
        .then((keys) => keys.length)
}

export function deleteUrls(urls) {
    return new Promise((resolve, reject) =>
        caches.open('osm-tiles-cache-v1')
            .then((cache) => {
                Promise.all(urls.map(url => cache.delete(url))).then(resolve).catch(reject)
            })
            .catch(reject)
    );
}

export function deleteAll() {
    return caches.delete('osm-tiles-cache-v1');
}