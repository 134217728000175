import React from 'react';
import { Button } from 'react-bootstrap';
import AppContext from './AppContext';
import EXIF from 'exif-js';
import moment from 'moment';

async function resizeBlob(blob) {
    const maxSizeInBytes = 2 * 1024 * 1024; // 2MB

    // Verifica se a imagem precisa ser redimensionada
    if (blob.size <= maxSizeInBytes) {
        return blob; // A imagem já está dentro do limite, retorna o blob original
    }    

    // Carrega o ImageBitmap diretamente do blob da imagem
    const imageBitmap = await createImageBitmap(blob);

    let width = imageBitmap.width;
    let height = imageBitmap.height;
    let scaleFactor = 1;

    // Calcula o fator de escala para redimensionar a imagem
    scaleFactor = Math.min(1, maxSizeInBytes / blob.size);
    width *= scaleFactor;
    height *= scaleFactor;

    // Cria um canvas com o tamanho redimensionado
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    // Desenha o ImageBitmap redimensionado no canvas
    const ctx = canvas.getContext('2d');
    ctx.drawImage(imageBitmap, 0, 0, width, height);

    // Obtenha o blob da imagem redimensionada do canvas
    return new Promise((resolve) => {
        canvas.toBlob((resizedBlob) => {
            resolve(resizedBlob);
        }, blob.type, 0.9); // 'image/jpeg' é o tipo do blob resultante e 0.9 é a qualidade da imagem (0 a 1)
    });
}

class ImageLoader extends React.Component {

    static contextType = AppContext;

    uploadRef = React.createRef();

    state = {
        loading: false,
        progress: 0
    };

    onChange(files) {

        this.setState(() => ({ loading: true, progress: 0 }));

        Promise.all(Array.from(files).map(file => {
            return new Promise((resolve, reject) => {

                EXIF.getData(file, async function () {
                    try {
                        const tags = EXIF.getAllTags(this);

                        const latitude = tags.latitude ??
                            (tags.GPSLatitude ? (tags.GPSLatitude[0] + tags.GPSLatitude[1] / 60 + tags.GPSLatitude[2] / 360) * (tags.GPSLatituderef === "N" ? 1 : -1) : undefined);
                        const longitude = tags.longitude ??
                            (tags.GPSLongitude ? (tags.GPSLongitude[0] + tags.GPSLongitude[1] / 60 + tags.GPSLongitude[2] / 360) * (tags.GPSLongituderef === "E" ? 1 : -1) : undefined);

                        var datahora = moment(file.lastModified ?? null);

                        if (tags.DateTimeOriginal) {
                            const regex = /^(\d{4}):(\d{2}):(\d{2}) (\d{2}):(\d{2}):(\d{2})$/;
                            const match = tags.DateTimeOriginal.match(regex);

                            if (match) {
                                const [, year, month, day, hour, minute, second] = match;
                                const formattedDateTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
                                const parsedDate = new Date(formattedDateTime);

                                datahora = moment(parsedDate);
                            }
                        }

                        const result = {
                            nome: file.name,
                            datahora,
                            blob: await resizeBlob(new Blob([file], { type: file.type }))
                        }

                        if (latitude) result.latitude = latitude.toFixed(8);
                        if (longitude) result.longitude = longitude.toFixed(8);

                        resolve(result);
                    } catch (error) {
                        reject(error);
                    }
                });
            });
        }))
            .then(files => this.props.onChange(files))
            .catch(error => {
                this.context.addToast({ header: "Erro", body: "Falha ao carregar arquivo: " + error.toString() })
            })
            .finally(() => this.setState(() => ({ loading: false })));
    }

    render() {
        return <div className={this.props.className}>
            <Button disabled={this.state.loading} type="button" className="form-control" variant="success" onClick={() => this.uploadRef.current.click()}>
                {this.props.title}
            </Button>
            <input type="file" accept="image/*" className="d-none" ref={this.uploadRef} multiple={this.props.multiple ?? false} onChange={event => this.onChange(event.target.files)} />
        </div >;
    }
}

export default ImageLoader;