import React from "react";
import AppContext from "../AppContext";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleHalfStroke, faCircleXmark, faFileEdit, faFilePdf, faTimes } from "@fortawesome/free-solid-svg-icons";

class RelatorioOptions extends React.Component {

    static contextType = AppContext;

    state = {
        sections: [
            { level: 0, index: "Projeto", title: "Incluir todos os sítios do Projeto", checked: false, disabled: this.props.disableProjeto },
            { level: 0, index: "Capa", title: "Capa", checked: true },
            { level: 0, index: "Índice", title: "Índice", checked: true },
            { level: 0, index: "1", title: "1 Dados Gerais", checked: true },
            { level: 1, index: "1.1", title: "1.1 Dados do Sítio", checked: true },
            { level: 1, index: "1.2", title: "1.2 Localização", checked: true },
            { level: 1, index: "1.3", title: "1.3 Dados da Propriedade", checked: true },
            { level: 0, index: "2", title: "2 Georreferenciamento", checked: true },
            { level: 1, index: "2.1", title: "2.1 Coordenadas", checked: true },
            { level: 1, index: "2.2", title: "2.2 Cartografia", checked: true },
            { level: 0, index: "3", title: "3 Categorização", checked: true },
            { level: 0, index: "4", title: "4 Paisagem", checked: true },
            { level: 1, index: "4.1", title: "4.1 Geografia", checked: true },
            { level: 1, index: "4.2", title: "4.2 Solo e Vegetação", checked: true },
            { level: 0, index: "5", title: "5 Estruturas", checked: true },
            { level: 1, index: "5.1", title: "5.1 Estruturas do Sítio", checked: true },
            { level: 1, index: "5.2", title: "5.2 Fatores de Destruição", checked: true },
            { level: 2, index: "5.2.1", title: "5.2.1 Possibilidades de Destruição", checked: true },
            { level: 2, index: "5.2.2", title: "5.2.2 Medidas de Proteção Tomadas", checked: true },
            { level: 0, index: "6", title: "6 Laboratório", checked: true },
            { level: 1, index: "6.1", title: "6.1 Materiais Coletados", checked: true },
            { level: 1, index: "6.2", title: "6.2 Filiações Culturais", checked: true },
            { level: 2, index: "6.2.1", title: "6.2.1 Artefatos Líticos", checked: true },
            { level: 2, index: "6.2.2", title: "6.2.2 Artefatos Cerâmicos", checked: true },
            { level: 2, index: "6.2.3", title: "6.2.3 Artefatos Rupestres", checked: true },
            { level: 1, index: "6.3", title: "6.3 Guarda do Acervo", checked: true },
            { level: 0, index: "7", title: "7 Instituição de Registro", checked: true },
            { level: 1, index: "7.1", title: "7.1 Dados da Instituição", checked: true },
            { level: 1, index: "7.2", title: "7.2 Dados do Responsável", checked: true },
            { level: 0, index: "8", title: "8 Anotações", checked: true },
            { level: 0, index: "9", title: "9 Imagens", checked: true },
            { level: 0, index: "10", title: "10 Conclusão", checked: true },
            { level: 0, index: "Anexos", title: "Anexos", checked: true },
            { level: 1, index: "A", title: "A - Tradagens", checked: true },
            { level: 2, index: "A.Imagens", title: "A - Tradagens - Imagens", checked: true }
        ]
    }

    render() {
        return <Container>
            <Row>
                <Col md="12">
                    <Row className="mb-3">
                        <Col md="4">
                            <Button className="form-control" variant="primary" onClick={() => this.setState(state => ({ sections: state.sections.map(section => ({ ...section, checked: true })) }))}>
                                <FontAwesomeIcon icon={faCircleCheck} /> Todos
                            </Button>
                        </Col>
                        <Col md="4">
                            <Button className="form-control" variant="dark" onClick={() => this.setState(state => ({ sections: state.sections.map(section => ({ ...section, checked: !section.checked })) }))}>
                                <FontAwesomeIcon icon={faCircleHalfStroke} /> Inverter
                            </Button>
                        </Col>
                        <Col md="4">
                            <Button className="form-control" variant="danger" onClick={() => this.setState(state => ({ sections: state.sections.map(section => ({ ...section, checked: false })) }))}>
                                <FontAwesomeIcon icon={faCircleXmark} /> Nenhum
                            </Button>
                        </Col>
                    </Row>
                    {this.state.sections.map(section => {
                        return <Form.Group>
                            <label className="d-block">
                                <input disabled={section.disabled} type="checkbox" checked={section.checked} onChange={e => this.setState(state => ({ sections: state.sections.map(s => s === section ? { ...section, checked: e.target.checked } : s) }))} />
                                <span className={section.checked ? "text-success" : "text-warning"} style={{ display: 'inline-block', marginLeft: section.level * 20 + 5 }}>{section.title}</span>
                            </label>
                        </Form.Group>
                    })}
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <Form.Group className="mt-2 d-flex justify-content-between">
                        <Button variant="secondary" onClick={() => this.context.closeModal()}>
                            <FontAwesomeIcon icon={faTimes} /> Cancelar
                        </Button>
                        <Button type="button" variant="info" onClick={() => this.props.onSave({ sections: this.state.sections, pdf: false, projeto: this.state.sections[0].checked })}>
                            <FontAwesomeIcon icon={faFileEdit} /> Gerar DOCX
                        </Button>
                        <Button type="button" variant="primary" onClick={() => this.props.onSave({ sections: this.state.sections, pdf: true, projeto: this.state.sections[0].checked })}>
                            <FontAwesomeIcon icon={faFilePdf} /> Gerar PDF
                        </Button>
                    </Form.Group>
                </Col>
            </Row>
        </Container>
    }

}

export default RelatorioOptions;