import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFastBackward, faGlobe, faTrash } from "@fortawesome/free-solid-svg-icons";
import AppContext from "../AppContext";
import IDBTable from "../IDBTable";
import MapCacheForm from "./MapCacheForm";
import * as IDB from "../../indexedDB";
import { cacheCount, deleteAll, deleteUrls } from "../../mapcache";
import { Button } from "react-bootstrap";

class MapCacheList extends React.Component {

    static contextType = AppContext;
    state = {
        total: 0,
        count: 0
    }

    myCallback(callback) {
        this.update();
        this.context.closeModal();
        callback();
    }

    onAdd(callback) {
        this.context.openModal({
            title: "Novo Cache de Mapa", body: <MapCacheForm onSave={() => this.myCallback(callback)} />
        });
    }

    onActionEdit(item, callback) {
        this.context.openModal({
            title: "Editar Cache de Mapa", body: <MapCacheForm item={item} onSave={() => this.myCallback(callback)} />
        });
    }

    onActionDelete(item, callback) {
        if (window.confirm("Deseja realmente excluir o item com ID " + item.id + "?")) {
            deleteUrls(item.urls).then(() => IDB.remove("MapCache", item.id)
                .then(() => {
                    this.context.addToast({ header: "Sucesso", body: "Item excluído com sucesso!" });
                    this.myCallback(callback);
                })
                .catch(error => {
                    this.context.addToast({ header: "Erro", body: "Falha ao excluir o item: " + error.toString() });
                })
            )
        }
    }

    clear() {
        if (window.confirm("Deseja realmente limpar todos os mapas?")) {
            IDB.clear("MapCache")
                .then(() => deleteAll())
                .then(() => {
                    this.update();
                    this.context.addToast({ header: "Sucesso", body: "Cache excluído com sucesso!" });
                })
                .catch(error => {
                    console.log(error);
                    this.context.addToast({ header: "Erro", body: "Falha ao excluir o cache: " + error.toString() });
                })
        }
    }

    update() {
        cacheCount().then(count => {
            this.setState({ count });
        })
    }

    componentDidMount() {
        this.update();
    }

    render() {
        return <>
            <h3 className="text-light d-flex align-items-center">
                <FontAwesomeIcon icon={faGlobe} className="mr-2" />Cache de Mapas
                <Button variant="secondary" className="ms-auto text-nowrap" onClick={() => this.context.home()}>
                    <FontAwesomeIcon icon={faFastBackward} /><span className="d-none d-md-inline">&nbsp;Voltar</span>
                </Button>
            </h3>
            <hr />
            <IDBTable
                storeName="MapCache"
                headers={["nome"]}
                onActionEdit={(item, callback) => this.onActionEdit(item, callback)}
                onActionDelete={(item, callback) => this.onActionDelete(item, callback)}
                onAdd={this.context.alive ? callback => this.onAdd(callback) : undefined}
            />
            <Button className="form-control" variant="danger" onClick={() => this.clear()}>
                <FontAwesomeIcon icon={faTrash} /> Excluir Tudo ({this.state.count} itens)
            </Button>
        </>
    }
}

export default MapCacheList;