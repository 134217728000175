import React from "react";
import AppContext from "../AppContext";
import { Accordion, Button, Col, Form, InputGroup, Row, Tab, Table, Tabs } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFastBackward, faFastForward, faHelmetSafety, faImage, faLocationDot, faMapMarkerAlt, faPlus, faSave, faSpinner, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import * as IDB from '../../indexedDB';
import ImageWithTag from "../ImageWithTag";
import MapWithMarker from "../MapWithMarker";
import moment from "moment";

import TradagemList from "./TradagemList";

class TradagemForm extends React.Component {

    static contextType = AppContext;

    state = {
        buscaCor: '',
        SitioId: null,
        UsoatualId: null,
        TipovegetacaoId: null,
        TiporelevoId: null,
        nome: "",
        nome_sitio: "Sem Sítio",
        nome_equipe: "",
        nome_responsavel: "",
        latitude: 0,
        longitude: 0,
        altitude: 0,
        precisao: 0,
        prospeccao: "",
        observacoes: "",
        ...(this.props.tradagem ?? {}),
        Anexos: this.props.tradagem?.Anexos.map(anexo => ({ ...anexo, datahora: moment(anexo.datahora) })) ?? [],
        Niveis: (this.props.tradagem?.Niveis ?? []).map(nivel => ({
            ...nivel,
            Coressolo: nivel.Coressolo?.map(cor => this.context.auxiliar.Corsolo.find(c => c.id === cor.id)) ?? []
        })),
        locating: false,
        tabKey: "Geral"
    };

    submit(event) {
        event.preventDefault();

        const errors = [];

        const fields = {
            nome: "Nome da Tradagem",
            nome_equipe: "Nome da Equipe",
            nome_responsavel: "Nome do Responsável",
            latitude: "Latitude Central",
            longitude: "Longitude Central",
        };
        Object.keys(fields).forEach((key, i) => {
            if (!this.state[key]) errors.push(<div key={i}>{fields[key]} não pode ser vazio.</div>);
        });
        if (this.state.prospeccao === "") errors.push(<div>"Haverá prospecção?" não pode ser vazio.</div>);
        this.state.Niveis.forEach(nivel => {            
            if (nivel.material === "") errors.push(<div>"Ocorreu material?" não pode ser vazio.</div>);            
        });
        this.state.Anexos.forEach(anexo => {            
            if (!anexo.blob) errors.push(<div>"Imagem da Amostra" não pode ser vazio.</div>);            
        });

        if (errors.length) {
            this.context.addToast({ header: "Erro de Validação", body: errors });
            return;
        }

        const tradagem = {
            ...this.state,
            Anexos: this.state.Anexos.map(anexo => ({
                ...anexo,
                datahora: anexo.datahora.toISOString()
            }))
        };

        const entry = this.props.index ? {
            index: this.props.index,
            tradagem,
            nome: tradagem.nome,
            responsavel: tradagem.nome_responsavel,
            sitio: tradagem.nome_sitio            
        } : {
            tradagem,
            responsavel: tradagem.nome_responsavel,
            nome: tradagem.nome,
            sitio: tradagem.nome_sitio
        }

        IDB.put("Tradagem", entry).then(result => {
            this.context.addToast({
                header: "Sucesso",
                body: "Alterações gravadas com sucesso!"
            });
            this.props.onSave(result);
        })
            .catch(error => {
                console.log(error);
                this.context.addToast({
                    header: "Erro",
                    body: "Erro na operação: " + error.message
                });
            });
    }

    toggleCorsolo(item, nivel) {
        this.setState(state => ({
            Niveis: state.Niveis.map(n => n === nivel ? {
                ...nivel,
                Coressolo: nivel.Coressolo.indexOf(item) < 0 ?
                    [...nivel.Coressolo, item] :
                    nivel.Coressolo.filter(e => e !== item)
            } : n)
        }));
    }

    onLocationError = error => {
        var message = "";
        switch (error.code) {
            case error.PERMISSION_DENIED:
                message = "Permissão de geolocalização negada pelo usuário. Acesse as configurações do seu dispositivo para liberar o recurso.";
                break;
            case error.POSITION_UNAVAILABLE:
                message = "Informação de geolocalização não disponível. Verifique se o GPS está ativo ou se possui conexão com a internet para uma localização aproximada.";
                break;
            case error.TIMEOUT:
                message = "Tempo limite expirado para obter a localização.";
                break;
            default:
                message = "Erro desconhecido ao obter a localização.";
                break;
        }
        this.context.addToast({ header: "Erro", body: message });
        this.setState({ locating: false });
    }

    getCoords() {
        return new Promise((resolve) => {
            const onLocationSuccess = position => {
                this.setState({ locating: false }, () => resolve(position.coords));
            }
            if (navigator.geolocation) {
                this.setState({ locating: true }, () => navigator.geolocation.getCurrentPosition(onLocationSuccess, this.onLocationError, { timeout: 15000 }));
            } else {
                this.context.addToast({ header: "Erro", body: "Dispositivo sem suporte a geolocalização." });
            }
        })
    }

    setLatLng(anexo) {
        this.getCoords().then(coords => {
            const latitude = coords.latitude.toFixed(6);
            const longitude = coords.longitude.toFixed(6);
            this.setState(state => ({ Anexos: state.Anexos.map((a, k) => anexo === a ? { ...a, latitude, longitude } : a) }))
        }).catch(error => {
            console.log(error);
            this.context.addToast({ header: "Erro", body: "Houve uma falha na geolocalização." });
        });
    }

    setLatLngCenter() {
        this.getCoords().then(coords => {
            const latitude = coords.latitude.toFixed(6);
            const longitude = coords.longitude.toFixed(6);
            const altitude = coords.altitude ?? 0;
            const precisao = parseInt(coords.accuracy ?? 0);
            this.setState({ latitude, longitude, altitude, precisao });
        });
    }

    addAnexos(anexos, camada) {
        this.setState(state => ({
            Anexos: [...state.Anexos, ...(anexos.map(anexo => ({ ...anexo, camada })))],
            activeKey: state.Anexos.length
        }));
    }

    delAnexo(anexo) {
        this.setState(state => ({
            Anexos: state.Anexos.filter(a => a !== anexo)
        }));
    }

    onChangeBlob(anexo, blob) {
        this.setState(state => ({
            Anexos: state.Anexos.map(a => a === anexo ? { ...a, blob } : a)
        }));
    }

    onChangeFile(anexo, file) {
        this.setState(state => ({
            Anexos: state.Anexos.map(a => a === anexo ? { ...a, ...file } : a)
        }));
    }

    componentDidMount() {
        if (!this.state.latitude && !this.state.longitude) {
            this.setLatLngCenter();
        }

        this.state.Anexos.filter(({ id, blob }) => id && !blob).forEach(anexo => {
            this.context.request("GET", `/anexo/${anexo.id}`, null, true)
                .then(response => response.blob())
                .then(blob => {
                    this.setState(state => ({ Anexos: state.Anexos.map(a => a === anexo ? { ...a, blob } : a) }))
                })
                .catch(error => {
                    console.log(error);
                    this.context.addToast({ header: "Erro", body: "Houve um erro no download das imagens: " + error.toString() });
                });
        });
    }



    render() {
        const {
            SitioId,
            UsoatualId,
            TipovegetacaoId,
            TiporelevoId,
            nome,
            nome_equipe,
            nome_responsavel,
            latitude,
            longitude,
            altitude,
            precisao,
            prospeccao,
            observacoes,
            Niveis,
            Anexos
        } = this.state;

        return <Form onSubmit={event => this.submit(event)} className="text-light form-large">
            <h4 className="text-light d-flex align-items-center">
                <FontAwesomeIcon icon={faHelmetSafety} className="mr-2" />Cadastro de Tradagem
                <div className="ms-auto text-nowrap">
                    <Button className="mr-2" variant="secondary" onClick={() => this.props.onCancel()}>
                        <FontAwesomeIcon icon={faTimes} /><span className="d-none d-md-inline">&nbsp;Cancelar</span>
                    </Button>
                    <Button type="submit" variant="primary">
                        <FontAwesomeIcon icon={faSave} /><span className="d-none d-md-inline">&nbsp;Salvar Rascunho</span>
                    </Button>
                </div>
            </h4>
            <hr />
            <Tabs onSelect={tabKey => this.setState({ tabKey })} activeKey={this.state.tabKey}>
                <Tab eventKey="Geral" title={<div title="Geral"><FontAwesomeIcon icon={faEdit} /><span className="d-none d-md-inline"> Geral</span></div>}>
                    <Row className="mt-3">
                        <Col md={12}><h4 className="text-light">Dados da Tradagem</h4></Col>                        
                        <Col md={12}>
                            <Form.Group className="mb-2">
                                <Form.Label className={nome ? '' : 'label-required'}>Nome da Tradagem</Form.Label>
                                <Form.Control value={nome} onChange={e => this.setState({ nome: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-2">
                                <Form.Label>Nome do Sítio</Form.Label>
                                <Form.Control as="select" value={SitioId?.toString() ?? ""} onChange={e => this.setState({ SitioId: e.target.value ? parseInt(e.target.value) : null, nome_sitio: e.target.value ? (this.context.auxiliar.SitioOption.find(sitio => sitio.id === parseInt(e.target.value))??{}).nome : "Sem Sítio" })}>
                                    <option value="">Sem Sítio</option>
                                    {this.context.auxiliar.SitioOption.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-2">
                                <Form.Label className={nome_equipe ? '' : 'label-required'}>Nome da Equipe</Form.Label>
                                <Form.Control value={nome_equipe} onChange={e => this.setState({ nome_equipe: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={8}>
                            <Form.Group className="mb-2">
                                <Form.Label className={nome_responsavel ? '' : 'label-required'}>Nome do Responsável</Form.Label>
                                <Form.Control value={nome_responsavel} onChange={e => this.setState({ nome_responsavel: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={8}>
                            <Form.Group className="mb-2">
                                <Form.Label>Uso atual do Terreno</Form.Label>
                                <Form.Control as="select" value={UsoatualId?.toString() ?? ""} onChange={e => this.setState({ UsoatualId: e.target.value ? parseInt(e.target.value) : null })}>
                                    <option value="">Selecione...</option>
                                    {this.context.auxiliar.Usoatual.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-2">
                                <Form.Label className={prospeccao !== "" ? '' : 'label-required'}>Haverá prospecção?</Form.Label>
                                <Form.Control as="select" value={prospeccao} onChange={e => this.setState({ prospeccao: e.target.value })}>
                                    <option value="">Selecione...</option>
                                    <option value={true}>Sim</option>
                                    <option value={false}>Não</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-2">
                                <Form.Label>Tipo de Vegetação</Form.Label>
                                <Form.Control as="select" value={TipovegetacaoId?.toString() ?? ""} onChange={e => this.setState({ TipovegetacaoId: e.target.value ? parseInt(e.target.value) : null })}>
                                    <option value="">Selecione...</option>
                                    {this.context.auxiliar.Tipovegetacao.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-2">
                                <Form.Label>Tipo de Relevo</Form.Label>
                                <Form.Control as="select" value={TiporelevoId?.toString() ?? ""} onChange={e => this.setState({ TiporelevoId: e.target.value ? parseInt(e.target.value) : null })}>
                                    <option value="">Selecione...</option>
                                    {this.context.auxiliar.Tiporelevo.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-2">
                                <Form.Label>Observações</Form.Label>
                                <Form.Control as="textarea" value={observacoes} onChange={e => this.setState({ observacoes: e.target.value })} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md="3" xs="4">
                            <Button variant="secondary" className="w-100" onClick={() => this.context.setContent(<TradagemList />)}>
                                <FontAwesomeIcon icon={faTimes} />
                                <span className="d-none d-md-inline"> Cancelar</span>
                            </Button>
                        </Col>
                        <Col xs={{ offset: 4, span: 4 }} md={{ offset: 6, span: 3 }}>
                            <Button variant="info" className="w-100" onClick={() => this.setState({ tabKey: "Georreferenciamento" }, () => this.context.scrollToTop())}>
                                <span className="d-none d-md-inline">Avançar </span><FontAwesomeIcon icon={faFastForward} />
                            </Button>
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="Georreferenciamento" title={<div title="Georreferenciamento"><FontAwesomeIcon icon={faMapMarkerAlt} /><span className="d-none d-md-inline"> Georreferenciamento</span></div>}>
                    <Row className="mt-3 d-flex align-items-stretch">
                        <Col md={6} className="flex-grow-1 flex-shrink-1 mb-2" style={{ minHeight: 420 }}>
                            <MapWithMarker
                                center={{ lat: latitude, lng: longitude }}
                                mutable={this.state.tabKey} />
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={12}><h4 className="text-light">Coordenadas</h4></Col>
                                <Col md={12}>
                                    <Form.Label className={latitude && longitude ? '' : 'label-required'}>Ponto Central</Form.Label>
                                    <InputGroup className="mb-2">
                                        <Form.Control type="number" value={latitude} onChange={e => this.setState({ latitude: e.target.value })} />
                                        <Form.Control type="number" value={longitude} onChange={e => this.setState({ longitude: e.target.value })} />
                                        <Button disabled={this.state.locating} variant="info" onClick={() => this.setLatLngCenter()}>
                                            {!this.state.locating ?
                                                <FontAwesomeIcon icon={faLocationDot} /> :
                                                <FontAwesomeIcon icon={faSpinner} spin />}
                                        </Button>
                                    </InputGroup>
                                    <Row>
                                        <Col className="mb-2">
                                            <Form.Label>Altitude (m)</Form.Label>
                                            <Form.Control type="number" value={altitude} onChange={e => this.setState({ altitude: e.target.value })} />
                                        </Col>
                                        <Col className="mb-2">
                                            <Form.Label>Precisão (m)</Form.Label>
                                            <Form.Control type="number" value={precisao} onChange={e => this.setState({ precisao: e.target.value })} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md="3" xs="4">
                            <Button variant="secondary" className="w-100" onClick={() => this.context.setContent(<TradagemList />)}>
                                <FontAwesomeIcon icon={faTimes} />
                                <span className="d-none d-md-inline"> Cancelar</span>
                            </Button>
                        </Col>
                        <Col md={{ offset: 3, span: 3 }} xs="4">
                            <Button variant="info" className="w-100" onClick={() => this.setState({ tabKey: "Geral" }, () => this.context.scrollToTop())}>
                                <FontAwesomeIcon icon={faFastBackward} /><span className="d-none d-md-inline"> Voltar</span>
                            </Button>
                        </Col>
                        <Col md="3" xs="4">
                            <Button variant="info" className="w-100" onClick={() => this.setState({ tabKey: "Níveis" }, () => this.context.scrollToTop())}>
                                <span className="d-none d-md-inline">Avançar </span><FontAwesomeIcon icon={faFastForward} />
                            </Button>
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="Níveis" title={<div title="Níveis"><FontAwesomeIcon icon={faImage} /><span className="d-none d-md-inline"> Níveis</span></div>}>

                    <Button variant="success" className="form-control mt-3" onClick={() => this.setState(state => ({ activeKey: state.Niveis.length, Anexos: [...state.Anexos, { camada: Math.max(...state.Niveis.map(n => n.camada), 0) + 1 }], Niveis: [...state.Niveis, { material: "", Coressolo: [], camada: Math.max(...state.Niveis.map(n => n.camada), 0) + 1 }] }))}>
                        <FontAwesomeIcon icon={faPlus} /> Adicionar Nível
                    </Button>

                    <Accordion defaultActiveKey={0} activeKey={this.state.activeKey} onSelect={activeKey => this.setState({ activeKey })} className="my-3">
                        {Niveis.map((nivel, key) =>
                            <Accordion.Item key={key} eventKey={key}>
                                <Accordion.Header>
                                    Camada {String.fromCharCode(64 + nivel.camada)} - {(nivel.camada - 1) * 10} a {nivel.camada * 10} cm
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row className="mt-3">
                                        <Col md={4} className="mb-3">
                                            <h4>
                                                Dados da Camada
                                                <hr style={{ border: "1px solid gray" }} />
                                            </h4>
                                            <Row>
                                                <Col md={12}>
                                                    <Form.Group className="mb-2">
                                                        <Form.Label className={nivel.material !== "" ? '' : 'label-required'}>Ocorreu material?</Form.Label>
                                                        <Form.Control as="select" value={nivel.material} onChange={e => this.setState(state => ({ Niveis: state.Niveis.map(n => n === nivel ? { ...nivel, material: e.target.value } : n) }))}>
                                                            <option value="">Selecione...</option>
                                                            <option value={true}>Sim</option>
                                                            <option value={false}>Não</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group className="mb-2">
                                                        <Form.Label>Tipo de Solo</Form.Label>
                                                        <Form.Control as="select" value={nivel.TiposoloId?.toString() ?? ""} onChange={e => this.setState(state => ({ Niveis: state.Niveis.map(n => n === nivel ? { ...nivel, TiposoloId: e.target.value ? parseInt(e.target.value) : null } : n) }))}>
                                                            <option value="">Selecione...</option>
                                                            {this.context.auxiliar.Tiposolo.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group className="mb-2">
                                                        <Form.Label>Textura do Solo</Form.Label>
                                                        <Form.Control as="select" value={nivel.TexturasoloId?.toString() ?? ""} onChange={e => this.setState(state => ({ Niveis: state.Niveis.map(n => n === nivel ? { ...nivel, TexturasoloId: e.target.value ? parseInt(e.target.value) : null } : n) }))}>
                                                            <option value="">Selecione...</option>
                                                            {this.context.auxiliar.Texturasolo.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="mb-2">
                                                        <Form.Label>Qtd. Lítico</Form.Label>
                                                        <Form.Control type="number" min={0} step={1} value={nivel.qtd_litico} onChange={e => this.setState(state => ({ Niveis: state.Niveis.map(n => n === nivel ? { ...nivel, qtd_litico: e.target.value } : n) }))} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="mb-2">
                                                        <Form.Label>Qtd. Cerâmico</Form.Label>
                                                        <Form.Control type="number" min={0} step={1} value={nivel.qtd_ceramico} onChange={e => this.setState(state => ({ Niveis: state.Niveis.map(n => n === nivel ? { ...nivel, qtd_ceramico: e.target.value } : n) }))} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="mb-2">
                                                        <Form.Label>Qtd. Histórico</Form.Label>
                                                        <Form.Control type="number" min={0} step={1} value={nivel.qtd_historico} onChange={e => this.setState(state => ({ Niveis: state.Niveis.map(n => n === nivel ? { ...nivel, qtd_historico: e.target.value } : n) }))} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="mb-2">
                                                        <Form.Label>Qtd. Orgânico</Form.Label>
                                                        <Form.Control type="number" min={0} step={1} value={nivel.qtd_organico} onChange={e => this.setState(state => ({ Niveis: state.Niveis.map(n => n === nivel ? { ...nivel, qtd_organico: e.target.value } : n) }))} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={4} className="mb-3">
                                            <h4>
                                                Imagem da Amostra <span className={Anexos.filter(anexo => anexo.camada === nivel.camada && anexo.blob).length ? '' : 'label-required'} />
                                                <hr style={{ border: "1px solid gray" }} />
                                            </h4>
                                            <Row>
                                                <Col md={12}>
                                                    {Anexos.filter(anexo => anexo.camada === nivel.camada).map((anexo, key) =>
                                                        <Row key={key}>
                                                            <Col md={6}>
                                                                <Form.Group className="mb-2">
                                                                    <Form.Label>Legenda</Form.Label>
                                                                    <Form.Control value={anexo.descricao ?? ""} onChange={e => this.setState(state => ({ Anexos: state.Anexos.map(a => a === anexo ? { ...anexo, descricao: e.target.value } : a) }))} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="mb-2">
                                                                    <Form.Label>Data/Hora</Form.Label>
                                                                    <Form.Control type="datetime-local" value={anexo.datahora?.format("YYYY-MM-DDTHH:mm") ?? moment().format("YYYY-MM-DDTHH:mm")} onChange={e => this.setState(state => ({ Anexos: state.Anexos.map(a => a === anexo ? { ...anexo, datahora: moment(e.target.value) } : a) }))} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={12}>
                                                                <Form.Label>Coordenadas</Form.Label>
                                                                <InputGroup className="mb-2">
                                                                    <Form.Control type="number" value={anexo.latitude ?? ""} onChange={e => this.setState(state => ({ Anexos: state.Anexos.map(a => a === anexo ? { ...anexo, latitude: e.target.value } : a) }))} />
                                                                    <Form.Control type="number" value={anexo.longitude ?? ""} onChange={e => this.setState(state => ({ Anexos: state.Anexos.map(a => a === anexo ? { ...anexo, longitude: e.target.value } : a) }))} />
                                                                    <Button disabled={this.state.locating} variant="info" onClick={() => this.setLatLng(anexo)}>
                                                                        {!this.state.locating ?
                                                                            <FontAwesomeIcon icon={faLocationDot} /> :
                                                                            <FontAwesomeIcon icon={faSpinner} spin />}
                                                                    </Button>

                                                                </InputGroup>
                                                            </Col>
                                                            <Col md={12}>
                                                                <Row className="d-flex align-items-stretch">
                                                                    <Col md={12} className="d-flex flex-column align-items-stretch justify-content-center" style={{ minHeight: 150 }}>
                                                                        <ImageWithTag
                                                                            blob={anexo.blob}
                                                                            onChangeBlob={blob => this.onChangeBlob(anexo, blob)}
                                                                            onChangeFile={file => this.onChangeFile(anexo, file)}
                                                                            lines={[
                                                                                (anexo.latitude && anexo.longitude) ? `Coord.: ${anexo.latitude}, ${anexo.longitude}` : "",
                                                                                anexo.datahora?.format("DD/MM/YYYY HH:mm") ?? "",
                                                                                anexo.descricao ?? ""
                                                                            ]} />
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={4} className="mb-3 overflow-auto" style={{ maxHeight: 400 }}>
                                            <h4>
                                                Cores do Solo
                                                <hr style={{ border: "1px solid gray" }} />
                                            </h4>
                                            <Table striped hover size="sm" className="mb-0">
                                                <thead>
                                                    <tr>
                                                        <th colSpan={4}>
                                                            <Form.Control value={this.state.buscaCor} onChange={e => this.setState({ buscaCor: e.target.value })} />
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th><input type="checkbox" readOnly checked={false} /></th>
                                                        <th>Código Munsell</th>
                                                        <th>RGB</th>
                                                        <th style={{ width: "1%" }}>Cor</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.context.auxiliar.Corsolo
                                                        .filter(cor => cor.nome.includes(this.state.buscaCor) || nivel.Coressolo.indexOf(cor) > -1)
                                                        .sort((a, b) => nivel.Coressolo.indexOf(a) > -1 ? (nivel.Coressolo.indexOf(b) > -1 ? a.id - b.id : -1) : (nivel.Coressolo.indexOf(b) > -1 ? 1 : a.id - b.id))
                                                        .slice(0, 20).map((cor, key) =>
                                                            <tr role="button" key={key} onClick={() => this.toggleCorsolo(cor, nivel)}>
                                                                <td style={{ width: '1%' }}><input readOnly type="checkbox" checked={nivel.Coressolo.indexOf(cor) > -1} /></td>
                                                                <td>{cor.nome}</td>
                                                                <td>{cor.rgb}</td>
                                                                <td style={{ width: "1%" }}>
                                                                    <div style={{ width: 40, height: 40, backgroundColor: cor.rgb }}></div>
                                                                </td>
                                                            </tr>)}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="mb-3">
                                            <Button className="form-control" variant="danger" onClick={() => this.setState(state => ({ Anexos: state.Anexos.filter(a => a.camada !== nivel.camada), Niveis: state.Niveis.filter(n => n !== nivel) }))}>
                                                <FontAwesomeIcon icon={faTrash} /> Excluir Nível
                                            </Button>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>)}
                    </Accordion>
                    <Row className="mt-3">
                        <Col md="3" xs="4">
                            <Button variant="secondary" className="w-100" onClick={() => this.context.setContent(<TradagemList />)}>
                                <FontAwesomeIcon icon={faTimes} />
                                <span className="d-none d-md-inline"> Cancelar</span>
                            </Button>
                        </Col>
                        <Col md={{ offset: 3, span: 3 }} xs="4">
                            <Button variant="info" className="w-100" onClick={() => this.setState({ tabKey: "Georreferenciamento" }, () => this.context.scrollToTop())}>
                                <FontAwesomeIcon icon={faFastBackward} /><span className="d-none d-md-inline"> Voltar</span>
                            </Button>
                        </Col>
                        <Col md="3" xs="4">
                            <Button type="submit" variant="primary" className="w-100">
                                <FontAwesomeIcon icon={faSave} /><span className="d-none d-md-inline">&nbsp;Salvar Rascunho</span>
                            </Button>
                        </Col>
                    </Row>
                </Tab>
            </Tabs >
        </Form >
    }
}

export default TradagemForm;