import React, { useEffect } from 'react';
import { MapContainer, Marker, Polygon, TileLayer, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import markerIcon from "../images/marker-icon.png";

const customIcon = L.icon({
    iconUrl: markerIcon,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
});

//Atualiza a posição do mapa caso haja movimentação externa
const ChangeHandler = ({ center, mutable }) => {

    const map = useMapEvents({
        resize: () => map.setView(center),        
    });
    
    useEffect(() => {
        map.invalidateSize();
    }, [map, mutable])

}

class MapWithMoveCallback extends React.Component {

    render() {
        return (
            <MapContainer 
            center={this.props.center}
            zoom={14} 
            style={{ width: '100%', height: '100%' }} 
            scrollWheelZoom="center">
                <TileLayer url="https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                <Marker
                    icon={customIcon}
                    position={this.props.center}
                    draggable={false}
                />
                {this.props.corners? <Polygon positions={this.props.corners} /> : "" }              
                <ChangeHandler center={this.props.center} mutable={this.props.mutable}/>
            </MapContainer>
        );
    }
};

export default MapWithMoveCallback;
