import React from 'react';
import AppContext from './AppContext';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faKey, faSignIn } from '@fortawesome/free-solid-svg-icons';
import ForgotPasswordForm from './ForgotPasswordForm';

class LoginForm extends React.Component {
    static contextType = AppContext;
    state = {
        email: "",
        senha: ""
    }

    submit(event) {
        event.preventDefault();
        const { email, senha } = this.state;
        this.context.logon(email, senha);
    }

    recover() {
        this.context.openModal({
            title: "Recuperação de Senha",
            body: <ForgotPasswordForm />
        });
    }

    render() {
        return <Form onSubmit={event => this.submit(event)}>
            <Row className='m-0'>
                <Col md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} className='mt-5'>
                    <Card>
                        <Card.Header>
                            <Card.Title>Acesso ao Sistema</Card.Title>
                        </Card.Header>
                        <Card.Body className='text-right'>
                            <Form.Label className='d-block text-left' style={{fontWeight: 'bold'}}>Email</Form.Label>
                            <InputGroup className="mb-3">
                                <InputGroup.Text><FontAwesomeIcon icon={faEnvelope} /></InputGroup.Text>
                                <Form.Control placeholder='Email' required type="text" value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
                            </InputGroup>
                            <Form.Label className='d-block text-left' style={{fontWeight: 'bold'}}>Senha</Form.Label>
                            <InputGroup className="mb-1">
                                <InputGroup.Text><FontAwesomeIcon icon={faKey} /></InputGroup.Text>
                                <Form.Control placeholder='Senha' required type="password" value={this.state.senha} onChange={e => this.setState({ senha: e.target.value })} />
                            </InputGroup>
                        </Card.Body>
                        <Card.Footer className='text-center'>
                            <Button type="submit" variant="success" className='form-control'>
                                <FontAwesomeIcon icon={faSignIn} /> &nbsp;
                                Entrar
                            </Button>                            
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </Form>

    }
}

export default LoginForm;
