import React from "react";
import AppContext from "./AppContext";
import { faCogs, faGlobe, faHelmetSafety, faLayerGroup, faMapMarkedAlt, faMasksTheater, faMicroscope, faSearchLocation, faSignOut, faSync, faTrowel, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Row } from "react-bootstrap";
import UsuarioList from "./admin/UsuarioList";
import AuxiliarList from "./admin/AuxiliarList";
import SitioList from "./sitio/SitioList";
import TradagemList from "./tradagem/TradagemList";
import MapCacheList from "./admin/MapCacheList";

class HomePage extends React.Component {
    static contextType = AppContext;
    render() {
        return <Container className="p-3">
            <Row>
                <Col sm={4} className="text-center my-3">
                    <Button className="w-100 h-100 p-3" variant="dark" onClick={() => this.context.setContent(<SitioList />)}>
                        <FontAwesomeIcon icon={faMapMarkedAlt} size="5x" /><br />
                        Sítios
                    </Button>
                </Col>
                <Col sm={4} className="text-center my-3">
                    <Button className="w-100 h-100 p-3" variant="dark" onClick={() => this.context.setContent(<TradagemList />)} >
                        <FontAwesomeIcon icon={faHelmetSafety} size="5x" /><br />
                        Tradagens
                    </Button>
                </Col>
                <Col sm={4} className="text-center my-3">
                    <Button className="w-100 h-100 p-3" variant="dark" disabled>
                        <FontAwesomeIcon icon={faTrowel} size="5x" /><br />
                        Escavações
                    </Button>

                </Col>
                <Col sm={4} className="text-center my-3">
                    <Button className="w-100 h-100 p-3" variant="dark" disabled>
                        <FontAwesomeIcon icon={faSearchLocation} size="5x" /><br />
                        Vistorias
                    </Button>
                </Col>                
                <Col sm={4} className="text-center my-3">
                    <Button className="w-100 h-100 p-3" variant="dark" disabled>
                        <FontAwesomeIcon icon={faLayerGroup} size="5x" /><br />
                        Perfis
                    </Button>
                </Col>
                <Col sm={4} className="text-center my-3">
                    <Button className="w-100 h-100 p-3" variant="dark" disabled>
                        <FontAwesomeIcon icon={faMasksTheater} size="5x" /><br />
                        Imaterial
                    </Button>
                </Col>
                <Col sm={4} className="text-center my-3">
                    <Button className="w-100 h-100 p-3" variant="dark" disabled>
                        <FontAwesomeIcon icon={faMicroscope} size="5x" /><br />
                        Laboratório
                    </Button>
                </Col>
                {this.context.usuario?.perfil === "Administrador" ?
                    <>
                        <Col sm={4} className="text-center my-3">
                            <Button className="w-100 h-100 p-3" variant="dark" disabled={!this.context.alive} onClick={() => this.context.setContent(<UsuarioList />)}>
                                <FontAwesomeIcon icon={faUsers} size="5x" /><br />
                                Usuários
                            </Button>
                        </Col>
                        <Col sm={4} className="text-center my-3">
                            <Button className="w-100 h-100 p-3" variant="dark" disabled={!this.context.alive} onClick={() => this.context.setContent(<AuxiliarList />)}>
                                <FontAwesomeIcon icon={faCogs} size="5x" /><br />
                                Configurações
                            </Button>
                        </Col>
                    </> : null}
                <Col sm={4} className="text-center my-3">
                    <Button className="w-100 h-100 p-3" variant="dark" disabled={!this.context.alive} onClick={() => this.context.sync()}>
                        <FontAwesomeIcon icon={faSync} size="5x" spin={this.context.syncing} /><br />
                        Sincronizar
                    </Button>
                </Col>

                <Col sm={4} className="text-center my-3">
                    <Button className="w-100 h-100 p-3" variant="dark" onClick={() => this.context.setContent(<MapCacheList />)}>
                        <FontAwesomeIcon icon={faGlobe} size="5x" /><br />
                        Cache de Mapas
                    </Button>
                </Col>
                <Col sm={4} className="text-center my-3">
                    <Button className="w-100 h-100 p-3" variant="danger" onClick={() => this.context.logoff()}>
                        <FontAwesomeIcon icon={faSignOut} size="5x" /><br />
                        Sair
                    </Button>
                </Col>
            </Row>
        </Container>;
    }
}

export default HomePage;