import * as Icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, ButtonGroup, Form, Table } from "react-bootstrap";
import AppContext from './AppContext';

class Datatable extends React.Component {

    static contextType = AppContext;

    timeout = null;

    state = {
        data: {
            headers: [],
            rows: []
        },
        limit: 10,
        offset: 0,
        filter: ''
    }

    update() {

        const { limit, offset, filter } = this.state;

        const query = new URLSearchParams({ limit, offset, filter });
        this.context.request("GET", `${this.props.endpoint}/?${query}`)
            .then(data => this.setState({ data }))
            .catch(error => {
                console.log(error);
                this.context.addToast({header: "Erro", body: "Falha ao buscar as informações da tabela"});
            });
    }

    componentDidUpdate(prevProps) {
        if (this.props.endpoint !== prevProps.endpoint) {
            this.setState({
                data: {
                    headers: [],
                    rows: []
                },
                limit: 10,
                offset: 0,
                filter: ''
            }, () => this.update());
        }
    }

    scheduleUpdate() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => this.update(), 1000);
    }

    componentDidMount(){
        this.update();
    }

    render() {

        const page = (this.state.limit ? this.state.offset / this.state.limit : 0) + 1;
        const tools = <tr>
            <td colSpan={this.state.data.headers.length + 1} className="">
                <Form onSubmit={event => this.search(event)} className="d-flex flex-wrap">
                    {this.props.onAdd &&
                        <Button size="sm" variant="success" className="text-nowrap flex-fill m-1" onClick={() => this.props.onAdd(() => this.update())}>
                            <FontAwesomeIcon icon={Icons.faPlus} /> Adicionar
                        </Button>}
                    <Form.Control size="sm" className="flex-fill flex-nowrap w-auto m-1" placeholder="Pesquisar..." value={this.state.filter} onChange={e => this.setState({ filter: e.target.value }, () => this.scheduleUpdate())} />
                    <Form.Control size="sm" as="select" className="w-auto flex-fill m-1" value={this.state.limit} onChange={e => this.setState({ limit: parseInt(e.target.value), offset: 0 }, () => this.update())}>
                        <option value={10}>10 registros</option>
                        <option value={20}>20 registros</option>
                        <option value={50}>50 registros</option>
                        <option value={100}>100 registros</option>
                        <option value={0}>Todos os registros</option>
                    </Form.Control>
                    <ButtonGroup className="d-flex flex-nowrap w-auto flex-fill m-1">
                        <Button size="sm" variant="outline-success" disabled={page === 1} onClick={() => this.setState(state => ({ offset: state.offset - state.limit }), () => this.update())}>
                            <FontAwesomeIcon icon={Icons.faBackward} />
                        </Button>
                        <Button size="sm" variant="outline-success" className="flex-grow-1">Página {page}</Button>
                        <Button size="sm" variant="outline-success" disabled={this.state.data.rows.length === 0 || this.state.limit === 0}>
                            <FontAwesomeIcon icon={Icons.faForward} onClick={() => this.setState(state => ({ offset: state.offset + state.limit }), () => this.update())} />
                        </Button>
                    </ButtonGroup>
                </Form>
            </td>
        </tr>;

        return <Table responsive>
            <thead>
                <tr>
                    {this.state.data.headers.map((header, key) =>
                        <th key={key}>
                            {header}
                        </th>)}
                    {this.props.onAction &&
                        <th className="text-center" style={{ width: '1%' }}>
                            Ações
                        </th>}
                </tr>
            </thead>
            <tbody>
                {this.state.data.rows.length > 0 ?
                    this.state.data.rows.map((row, key) => <tr key={key}>
                        {row.values.map((value, key) =>
                            <td key={key} style={{ width: !key ? '1%' : undefined, verticalAlign: "middle" }}>
                                {value}
                            </td>)}
                        {this.props.onAction &&
                            <td className="text-center text-nowrap" style={{ width: '1%' }}>
                                {row.actions.length > 0 ? row.actions.map((action, key) =>
                                    <Button size="sm" key={key} title={action.title} className="mx-1" variant={action.variant} onClick={() => this.props.onAction(action, () => this.update())}>
                                        <FontAwesomeIcon icon={Icons[action.icon]} />
                                    </Button>
                                ) : "Sem Ações"}
                            </td>}
                    </tr>) :
                    <tr>
                        <td colSpan={this.state.data.headers.length + (this.props.onAction ? 1 : 0)} className="text-center">
                            Nenhum resultado encontrado.
                        </td>
                    </tr>}
            </tbody>
            <tfoot>
                {tools}
            </tfoot>
        </Table>;
    }
}

export default Datatable;