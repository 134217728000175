import React from "react";
import Datatable from "../Datatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs, faFastBackward } from "@fortawesome/free-solid-svg-icons";
import AppContext from "../AppContext";
import AuxiliarForm from "./AuxiliarForm";
import { Button, Form } from "react-bootstrap";

const tabelas = [

    {nome: "Projeto", titulo: "Projeto", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Cidade", titulo: "Cidade", campos: [{nome: "nome", titulo: "Nome"}, { nome: "EstadoId", titulo: "ID do Estado" }]},
    {nome: "Estado", titulo: "Estado", campos: [{nome: "nome", titulo: "Nome"}, { nome: "sigla", titulo: "Sigla da UF" }]},
    {nome: "Tiporelevo", titulo: "Tipo de Relevo", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Propriedadeterra", titulo: "Propriedade da Terra", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Protecaolegal", titulo: "Proteção Legal", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Categoriasitio", titulo: "Categoria do Sítio", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Arterupestre", titulo: "Arte Rupestre", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Exposicaositio", titulo: "Exposição do Sítio", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Formasitio", titulo: "Forma do Sítio", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Contextodeposicao", titulo: "Contexto da Deposição", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Densidadematerial", titulo: "Densidade do Material", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Integridadesitio", titulo: "Integridade do Sítio", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Relevanciasitio", titulo: "Relevancia do Sítio", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Texturasolo", titulo: "Textura do Solo", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Estadosolo", titulo: "Estado do Solo", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Modomedicao", titulo: "Modo de Medição", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Metodomedicao", titulo: "Método de Medição", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Orgaoeditor", titulo: "Orgão Editor", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Unidadegeomorfologica", titulo: "Unidade Geomorfológica", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Compartimentotopografico", titulo: "Compartimento Topográfico", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Datum", titulo: "Datum", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Corsolo", titulo: "Cor do Solo", campos: [{nome: "nome", titulo: "Nome"}, { nome: "codigo", titulo: "Código da Cor" }, { nome: "rgb", titulo: "RGB da Cor" }]},
    {nome: "Tiposolo", titulo: "Tipo de Solo", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Tipovegetacao", titulo: "Tipo de Vegetação", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Atividade", titulo: "Atividade", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Tipositio", titulo: "Tipo de Sítio", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Usoatual", titulo: "Uso Atual", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Estruturasitio", titulo: "Estrutura do Sítio", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Fatordestruicao", titulo: "Fator de Destruição", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Material", titulo: "Material", campos: [{nome: "nome", titulo: "Nome"}]},
    {nome: "Tipomaterial", titulo: "Tipo de Material", campos: [{nome: "nome", titulo: "Nome"}, { nome: "TipomaterialId", titulo: "ID do Tipo" }]}  
].sort((a, b) => a.nome.localeCompare(b.nome));

class AuxiliarList extends React.Component {

    static contextType = AppContext;

    state = {
        tabela: { nome: "" }
    }

    myCallback(callback) {
        this.context.closeModal();
        callback();
    }

    onAction(action, callback) {
        switch (action.title) {
            case "Editar":
                this.context.request("GET", `/auxiliar/${this.state.tabela.nome}/${action.id}`).then(auxiliar => {
                    this.context.openModal({
                        title: "Editar " + this.state.tabela.titulo, body: <AuxiliarForm tabela={this.state.tabela} auxiliar={auxiliar} onSave={() => this.myCallback(callback)} />
                    });
                });
                break;
            case "Excluir":
                if (window.confirm(`Deseja realmente excluir o item ${this.state.tabela.titulo} com ID ${action.id}?`)) {
                    this.context.request("DELETE", `/auxiliar/${this.state.tabela.nome}/${action.id}`).then(() => {
                        this.context.addToast({ header: "Sucesso", body: "Item excluído com sucesso!" });
                        callback();
                    });
                }
                break;
            default:
                break;
        }
    }

    onAdd(callback) {
        this.context.openModal({
            title: "Novo(a) " + this.state.tabela.titulo, body: <AuxiliarForm tabela={this.state.tabela} onSave={() => this.myCallback(callback)} />
        });
    }

    render() {
        return <>
            <h3 className="text-light d-flex align-items-center">
                <FontAwesomeIcon icon={faCogs} className="mr-2" />Tabelas Auxiliares
                <Button variant="secondary" className="ms-auto text-nowrap" onClick={() => this.context.home()}>
                    <FontAwesomeIcon icon={faFastBackward} /><span className="d-none d-md-inline">&nbsp;Voltar</span>
                </Button>
            </h3>
            <hr/>
            <Form.Label as="h4" className="text-light">Tabela</Form.Label>
            <Form.Control className="mb-3" as="select" value={this.state.tabela.nome} onChange={e => this.setState({ tabela: tabelas.find(t => t.nome === e.target.value) ?? { nome: "" } })}>
                <option value="">Selecione uma tabela auxiliar...</option>
                {tabelas.map((tabela, key) => <option key={key} value={tabela.nome}>{tabela.titulo}</option>)}
            </Form.Control>
            {this.state.tabela.nome && <Datatable
                endpoint={`/auxiliar/${this.state.tabela.nome}`}
                onAction={(action, callback) => this.onAction(action, callback)}
                onAdd={callback => this.onAdd(callback)}
            />}

        </>
    }
}

export default AuxiliarList;