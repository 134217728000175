import React from "react";
import Datatable from "../Datatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFastBackward, faMapMarked, faPlus, faSync } from "@fortawesome/free-solid-svg-icons";
import AppContext from "../AppContext";
import SitioForm from "./SitioForm";
import { Button, Tab, Tabs } from "react-bootstrap";
import * as IDB from "../../indexedDB";
import IDBTable from "../IDBTable";
import moment from "moment";
import RelatorioOptions from "./RelatorioOptions";

class SitioList extends React.Component {

    static contextType = AppContext;

    myCallback() {
        this.context.setContent(<SitioList key={new Date().getUTCMilliseconds()} />);
    }

    onActionEdit(item) {
        this.context.setContent(<SitioForm index={item.index} sitio={item.sitio} onCancel={() => this.myCallback()} onSave={() => this.myCallback()} />);
    }

    onActionSyncAll() {
        IDB.getAll("Sitio")
            .then(items => Promise.all(items.map(item => this.syncItem(item))))
            .then(() => {
                this.context.addToast({ header: "Sucesso", body: "Todos os rascunhos foram sincronizados" });
            })
            .catch(error => {
                this.context.addToast({ header: "Erro", body: "Falha ao sincronizar os Rascunhos: " + error.toString() });
            })
            .finally(() => {
                this.myCallback();
            });
    }

    blobToBase64(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    syncItem(item) {

        return new Promise(async (resolve, reject) => {
            const { index, sitio } = item;
            const { id } = sitio;
            const method = id ? "PUT" : "POST";

            this.context.request(method, `/sitio/${id ? id : ''}`, { ...sitio, Anexos: undefined })
                .then(({ SitioId }) => {

                    const data = new FormData();

                    sitio.Anexos.forEach((anexo, i) => {
                        if (anexo.id) data.append(`AnexosMeta[${i}][id]`, anexo.id);
                        data.append(`AnexosMeta[${i}][nome]`, anexo.nome);
                        data.append(`AnexosMeta[${i}][latitude]`, anexo.latitude ?? 0);
                        data.append(`AnexosMeta[${i}][longitude]`, anexo.longitude ?? 0);
                        data.append(`AnexosMeta[${i}][descricao]`, anexo.descricao ?? "");
                        data.append(`AnexosMeta[${i}][datahora]`, anexo.datahora ?? moment().format("YYYY-MM-DDTHH:ss"));
                        data.append(`AnexosMeta[${i}][SitioId]`, SitioId);
                        data.append(`AnexosFile`, anexo.blob);
                    });

                    return this.context.request("PUT", `/sitio/${SitioId}/anexo`, data);
                })
                .then(() => IDB.remove("Sitio", index))
                .then(resolve)
                .catch(reject);
        });
    }

    onActionSync(item, callback) {
        this.syncItem(item)
            .then(() => {
                this.context.addToast({ header: "Sucesso", body: "Rascunho sincronizado com sucesso." });
            })
            .then(callback)
            .catch(error => {
                this.context.addToast({ header: "Erro", body: "Falha ao sincronizar o Sítio: " + error.toString() });
            });
    }

    onActionDelete(item, callback) {
        if (window.confirm("Deseja realmente excluir o Rascunho com índice " + item.index + "?")) {
            IDB.remove("Sitio", item.index)
                .then(() => {
                    this.context.addToast({ header: "Sucesso", body: "Item excluído com sucesso!" });
                    callback();
                })
                .catch(error => {
                    this.context.addToast({ header: "Erro", body: "Falha ao excluir o item: " + error.toString() });
                });
        }
    }


    onAction(action, callback) {
        switch (action.title) {
            case "Editar":
                this.context.request("GET", `/sitio/${action.id}`).then(sitio => {
                    const Anexos = sitio.Anexos.map((anexo) => ({ ...anexo }))
                    this.context.setContent(<SitioForm sitio={{ ...sitio, Anexos }} onCancel={() => this.myCallback()} onSave={() => this.myCallback()} />);
                });
                break;
            case "Ficha de Cadastro":
                this.context.request("GET", `/sitio/${action.id}/ficha`, null, true)
                    .then(response => response.blob())
                    .then(blob => window.open(URL.createObjectURL(blob)))
                    .catch(error => {
                        this.context.addToast({ header: "Erro", body: "Erro ao criar o relatório: " + error.toString() });
                    });
                break;
            case "Relatório":
                this.context.openModal({
                    title: "Relatório de Levantamento de Sítio", body: <RelatorioOptions disableProjeto={!action.ProjetoId} onSave={data => {
                        this.context.closeModal();
                        const url = data.projeto && action.ProjetoId? `/projeto/${action.ProjetoId}/relatorio` : `/sitio/${action.id}/relatorio`;
                        this.context.request("POST", url, data, true)
                            .then(response => response.blob())
                            .then(blob => window.open(URL.createObjectURL(blob)))
                            .catch(error => {
                                this.context.addToast({ header: "Erro", body: "Erro ao criar o relatório: " + error.toString() });
                            });
                    }} />
                });
                break;
            case "Excluir":
                if (window.confirm("Deseja realmente excluir o sítio com ID " + action.id + "?")) {
                    this.context.request("DELETE", `/sitio/${action.id}`).then(() => {
                        this.context.addToast({ header: "Sucesso", body: "Sítio excluído com sucesso!" });
                        callback();
                    });
                }
                break;
            default:
                break;
        }
    }

    onAdd() {
        this.context.setContent(<SitioForm onCancel={() => this.myCallback()} onSave={() => this.myCallback()} />);
    }

    render() {
        return <>
            <h3 className="d-flex justify-content-between text-light">
                <div className="text-nowrap">
                    <FontAwesomeIcon icon={faMapMarked} /> Sítios
                </div>
                <div className="text-nowrap">
                    <Button variant="secondary" className="mx-1 text-nowrap" onClick={() => this.context.home()}>
                        <FontAwesomeIcon icon={faFastBackward} /><span className="d-none d-md-inline">&nbsp;Voltar</span>
                    </Button>
                    <Button variant="info" className="mx-1 text-nowrap" onClick={() => this.onActionSyncAll()} disabled={!this.context.alive}>
                        <FontAwesomeIcon icon={faSync} /><span className="d-none d-md-inline">&nbsp;Sincronizar</span>
                    </Button>
                    <Button variant="success" className="mx-1 text-nowrap" onClick={() => this.onAdd()}>
                        <FontAwesomeIcon icon={faPlus} /><span className="d-none d-md-inline">&nbsp;Adicionar Sítio</span>
                    </Button>
                </div>
            </h3>
            <hr />
            <Tabs>
                <Tab eventKey={"Rascunhos"} title={<span><FontAwesomeIcon icon={faEdit} /> Rascunhos</span>}>
                    <IDBTable
                        storeName="Sitio"
                        headers={["nome", "responsavel"]}
                        onActionEdit={(item, callback) => this.onActionEdit(item, callback)}
                        onActionDelete={(item, callback) => this.onActionDelete(item, callback)}
                        onActionSync={(item, callback) => this.onActionSync(item, () => { callback(); this.myCallback(); })}
                    />
                </Tab>
                <Tab eventKey={"Sincronizados"} title={<span><FontAwesomeIcon icon={faSync} /> Sincronizados</span>}>
                    {this.context.alive ?
                        <Datatable
                            endpoint="/sitio"
                            onAction={(action, callback) => this.onAction(action, callback)}
                        />
                        : <h3 className="text-white text-center mt-3">Servidor Offline</h3>}
                </Tab>
            </Tabs>
        </>
    }
}

export default SitioList;