export const auxiliarTables = [
    "Projeto",
    "Cidade",
    "Estado",
    "Tiporelevo",
    "Propriedadeterra",
    "Protecaolegal",
    "Categoriasitio",
    "Arterupestre",
    "Exposicaositio",
    "Formasitio",
    "Contextodeposicao",
    "Densidadematerial",
    "Integridadesitio",
    "Relevanciasitio",
    "Texturasolo",
    "Estadosolo",
    "Modomedicao",
    "Metodomedicao",
    "Orgaoeditor",
    "Unidadegeomorfologica",
    "Compartimentotopografico",
    "Datum",
    "Corsolo",
    "Tiposolo",
    "Tipovegetacao",
    "Atividade",
    "Tipositio",
    "Usoatual",
    "Estruturasitio",
    "Fatordestruicao",
    "Material",
    "Tipomaterial",
    "SitioOption"
];

export const versions = [
    [
       { storeName: "Projeto", autoIncrement: false, keyPath: "id"},
       { storeName: "Cidade", autoIncrement: false, keyPath: "id"},
       { storeName: "Estado", autoIncrement: false, keyPath: "id"},
       { storeName: "Tiporelevo", autoIncrement: false, keyPath: "id"},
       { storeName: "Propriedadeterra", autoIncrement: false, keyPath: "id"},
       { storeName: "Protecaolegal", autoIncrement: false, keyPath: "id"},
       { storeName: "Categoriasitio", autoIncrement: false, keyPath: "id"},
       { storeName: "Arterupestre", autoIncrement: false, keyPath: "id"},
       { storeName: "Exposicaositio", autoIncrement: false, keyPath: "id"},
       { storeName: "Formasitio", autoIncrement: false, keyPath: "id"},
       { storeName: "Contextodeposicao", autoIncrement: false, keyPath: "id"},
       { storeName: "Densidadematerial", autoIncrement: false, keyPath: "id"},
       { storeName: "Integridadesitio", autoIncrement: false, keyPath: "id"},
       { storeName: "Relevanciasitio", autoIncrement: false, keyPath: "id"},
       { storeName: "Texturasolo", autoIncrement: false, keyPath: "id"},
       { storeName: "Estadosolo", autoIncrement: false, keyPath: "id"},
       { storeName: "Modomedicao", autoIncrement: false, keyPath: "id"},
       { storeName: "Metodomedicao", autoIncrement: false, keyPath: "id"},
       { storeName: "Orgaoeditor", autoIncrement: false, keyPath: "id"},
       { storeName: "Unidadegeomorfologica", autoIncrement: false, keyPath: "id"},
       { storeName: "Compartimentotopografico", autoIncrement: false, keyPath: "id"},
       { storeName: "Datum", autoIncrement: false, keyPath: "id"},
       { storeName: "Corsolo", autoIncrement: false, keyPath: "id"},
       { storeName: "Tiposolo", autoIncrement: false, keyPath: "id"},
       { storeName: "Tipovegetacao", autoIncrement: false, keyPath: "id"},
       { storeName: "Atividade", autoIncrement: false, keyPath: "id"},
       { storeName: "Tipositio", autoIncrement: false, keyPath: "id"},
       { storeName: "Usoatual", autoIncrement: false, keyPath: "id"},
       { storeName: "Estruturasitio", autoIncrement: false, keyPath: "id"},
       { storeName: "Fatordestruicao", autoIncrement: false, keyPath: "id"},
       { storeName: "Material", autoIncrement: false, keyPath: "id"},
       { storeName: "Tipomaterial", autoIncrement: false, keyPath: "id"},
       { storeName: "Token", autoIncrement: false, keyPath: "id"}
    ],[
        { storeName: "Sitio", autoIncrement: true, keyPath: "index"}
    ], [
        { storeName: "MapCache", autoIncrement: true, keyPath: "id"}
    ],[
        { storeName: "Tradagem", autoIncrement: true, keyPath: "index"},
        { storeName: "SitioOption", autoIncrement: true, keyPath: "index"}
    ]
];