import React from 'react';
import AppContext from './AppContext';
import {Container} from 'react-bootstrap';
import TopMenu from './TopMenu';
import BottomMenu from './BottomMenu';
import Toasts from './Toasts';
import AppModal from './AppModal';
import LoadingPage from './LoadingPage';
import OffcanvasMenu from './OffcanvasMenu';

class App extends React.Component {
    static contextType = AppContext;
    render() {
        return <Container fluid className="d-flex flex-column m-0 p-0"  style={{height:"100%"}}>
            <TopMenu />
            <div className='content flex-grow-1 flex-shrink-1 overflow-auto p-3' ref={this.context.contentRef}>
                {this.context.requestPending? <LoadingPage /> : this.context.content}
            </div>
            <BottomMenu />
            <Toasts />
            <AppModal />
            <OffcanvasMenu />
        </Container>
    }
}

export default App;
