import React from 'react';
import AppContext from './AppContext';
import { Badge, Navbar } from 'react-bootstrap';

class BottomMenu extends React.Component {
    static contextType = AppContext;
    render() {
        return <Navbar bg="light" className='d-flex justify-content-between px-2 py-0'>
            <Navbar.Text className="small">
                Agile ® 2023. Todos os direitos reservados.
            </Navbar.Text>
            <Badge bg={this.context.syncing? 'info' : (this.context.fetching? 'warning' : (this.context.alive? 'success' : 'danger'))}>
                {this.context.syncing? 'Sincronizando'  : (this.context.fetching? 'Buscando' : (this.context.alive? 'Online' : 'Offline'))}
            </Badge>
        </Navbar>
    }
}

export default BottomMenu;