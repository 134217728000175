import { faCameraAlt, faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import ImageLoader from './ImageLoader';

class ImageWithTag extends React.Component {

    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
        this.state = {
            url: props.blob ? URL.createObjectURL(props.blob) : null
        }
    }

    createTag() {

        const canvas = this.canvasRef.current;
        const ctx = canvas.getContext('2d');

        const image = new Image();

        image.onload = () => {

            const canvasWidth = image.width;
            const canvasHeight = image.height;

            canvas.width = canvasWidth;
            canvas.height = canvasHeight;

            ctx.drawImage(image, 0, 0);

            const tagFontSize = Math.min(canvasWidth, canvasHeight) * 0.05; // Ajuste o multiplicador para controlar o tamanho da fonte
            ctx.font = `${tagFontSize}px  Arial`;

            // Tamanho da tag
            const tagPadding = tagFontSize;
            const tagWidth = canvasWidth - 2 * tagPadding;
            const tagHeight = tagFontSize * (1 + 1.15 * this.props.lines.length);

            const tagX = canvasWidth - tagWidth - tagPadding;
            const tagY = canvasHeight - tagHeight - tagPadding;

            ctx.fillStyle = '#FFFF00';

            this.props.lines.forEach((line, n) => {
                ctx.fillText(line, tagX + tagPadding, tagY + (1 + 1.15 * n) * tagFontSize);
            });

            canvas.toBlob(blob => this.props.onChangeBlob(blob), "image/jpeg", 1.0);

        };

        image.src = this.state.url;
    }

    onChangeFile(files) {
        if (files.length) {
            const file = files[0];
            this.setState({ url: URL.createObjectURL(file.blob) }, () => this.props.onChangeFile(file));
        }
    }

    componentDidUpdate(props) {
        if (props.blob !== this.props.blob) {
            if (this.state.url) URL.revokeObjectURL(this.state.url);
            this.setState({ url: URL.createObjectURL(this.props.blob) });
        }
    }

    componentWillUnmount() {
        if (this.state.url) {
            URL.revokeObjectURL(this.state.url);
        }
    }

    render() {
        return <>
            <Row className='mb-2 flex-grow-1'>
                <canvas ref={this.canvasRef} style={{ display: "none" }} />
                <Col className='text-center p-3 d-flex flex-column align-items-center justify-content-center'>
                    {this.state.url ? <img alt="" src={this.state.url} style={{ maxHeight: 300, maxWidth: '100%' }} /> : "Sem Imagem"}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button variant="primary" className="form-control" onClick={() => this.createTag()}>
                        <FontAwesomeIcon icon={faTag} /> Etiquetar
                    </Button>
                </Col>
                <Col>
                    <ImageLoader multiple={false} onChange={anexos => this.onChangeFile(anexos)} title={<span><FontAwesomeIcon icon={faCameraAlt} /> Modificar</span>} />
                </Col>
            </Row>
        </>;
    }

};

export default ImageWithTag;
