import React from "react";
import AppContext from "../AppContext";
import { Accordion, Button, Col, Form, InputGroup, Row, Tab, Table, Tabs } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCameraAlt, faEdit, faFastBackward, faFastForward, faImage, faLandmark, faListCheck, faLocationDot, faMapMarkedAlt, faMapMarkerAlt, faMicroscope, faMountainSun, faPencil, faSave, faSpinner, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import * as IDB from '../../indexedDB';
import ImageLoader from "../ImageLoader";
import ImageWithTag from "../ImageWithTag";
import MapWithMarker from "../MapWithMarker";
import moment from "moment";

import * as geolib from 'geolib';
import SitioList from "./SitioList";

class SitioForm extends React.Component {

    static contextType = AppContext;

    state = {
        buscaCor: '',
        ProjetoId: null,
        EstadoId: this.props.sitio?.Cidade?.EstadoId ?? null,
        CidadeId: null,
        TiporelevoId: null,
        PropriedadeterraId: null,
        ProtecaolegalId: null,
        CategoriasitioId: null,
        ArterupestreId: null,
        ExposicaositioId: null,
        FormasitioId: null,
        ContextodeposicaoId: null,
        DensidadematerialId: null,
        IntegridadesitioId: null,
        RelevanciasitioId: null,
        TexturasoloId: null,
        EstadosoloId: null,
        ModomedicaoId: null,
        MetodomedicaoId: null,
        OrgaoeditorId: null,
        DatumId: null,
        UnidadegeomorfologicaId: null,
        CompartimentotopograficoId: null,
        nome: '',
        numero: '',
        designacao_outras: '',
        descricao: '',
        latitude: 0,
        longitude: 0,
        altitude: '',
        precisao: '',
        latitude_nw: 0,
        longitude_nw: 0,
        latitude_ne: 0,
        longitude_ne: 0,
        latitude_se: 0,
        longitude_se: 0,
        latitude_sw: 0,
        longitude_sw: 0,
        comprimento: 0,
        largura: 0,
        altura_maxima: 0,
        area: 0,
        documento_cartografico: '',
        ano_edicao: moment().year(),
        escala: '',
        documentacao: '',
        bibliografia: '',
        localidade: '',
        localidade_outras: '',
        endereco: '',
        acesso: '',
        nome_proprietario: '',
        endereco_proprietario: '',
        cep_proprietario: '',
        email_proprietario: '',
        contato_proprietario: '',
        nome_ocupante: '',
        agua_proxima: '',
        distancia_agua: '',
        nome_rioproximo: '',
        bacia: '',
        sitio_relacionado: '',
        medidas_preservacao: '',
        possibilidade_destruicao: '',
        qtd_coletasolo: 0,
        observacoes: '',
        atualizacoes: '',
        outros_artefatos: '',
        filiacao_cultural_litico_tradicoes: '',
        filiacao_cultural_litico_fases: '',
        filiacao_cultural_litico_complementos: '',
        filiacao_cultural_litico_outros: '',
        filiacao_cultural_ceramico_tradicoes: '',
        filiacao_cultural_ceramico_fases: '',
        filiacao_cultural_ceramico_complementos: '',
        filiacao_cultural_ceramico_outros: '',
        filiacao_cultural_rupestre_tradicoes: '',
        filiacao_cultural_rupestre_estilos: '',
        filiacao_cultural_rupestre_complementos: '',
        filiacao_cultural_rupestre_outros: '',
        datacao_absoluta: '',
        datacao_relativa: '',
        instituicoes_acervo: '',
        catalogos_acervo: '',
        outros_atividades: '',
        outros_usoatual: '',
        outros_estruturas: '',
        outros_fatores: '',
        instituicoes_guarda: '',
        nome_equipe: '',
        nome_responsavel: '',
        endereco_responsavel: '',
        cep_responsavel: '',
        email_responsavel: '',
        contato_responsavel: '',
        nome_instituicao: '',
        endereco_instituicao: '',
        cep_instituicao: '',
        email_instituicao: '',
        contato_instituicao: '',
        ...(this.props.sitio ?? {}),
        datahora: moment(this.props.sitio?.datahora),
        Coressolo: this.context.auxiliar.Corsolo.filter(item => this.props.sitio?.Coressolo?.find(({ id }) => item.id === id)),
        Estruturassitio: this.context.auxiliar.Estruturasitio.filter(item => this.props.sitio?.Estruturassitio?.find(({ id }) => item.id === id)),
        Fatoresdestruicao: this.context.auxiliar.Fatordestruicao.filter(item => this.props.sitio?.Fatoresdestruicao?.find(({ id }) => item.id === id)),
        Tipovegetacoes: this.context.auxiliar.Tipovegetacao.filter(item => this.props.sitio?.Tipovegetacoes?.find(({ id }) => item.id === id)),
        Tiposolos: this.context.auxiliar.Tiposolo.filter(item => this.props.sitio?.Tiposolos?.find(({ id }) => item.id === id)),
        Tipositios: this.context.auxiliar.Tipositio.filter(item => this.props.sitio?.Tipositios?.find(({ id }) => item.id === id)),
        Atividades: this.context.auxiliar.Atividade.filter(item => this.props.sitio?.Atividades?.find(({ id }) => item.id === id)),
        Usoatuais: this.context.auxiliar.Usoatual.filter(item => this.props.sitio?.Usoatuais?.find(({ id }) => item.id === id)),
        Materiais: this.context.auxiliar.Material.map(material => ({
            ...material,
            SitioMaterial: (this.props.sitio?.Materiais?.find(m => m.id === material.id) ?? { SitioMaterial: { quantidade: 0 } }).SitioMaterial
        })),
        Anexos: this.props.sitio?.Anexos.map(anexo => ({ ...anexo, datahora: moment(anexo.datahora) })) ?? [],
        locating: false,
        tabKey: "Geral"
    };

    submit(event) {
        event.preventDefault();

        const errors = [];

        const fields = {
            nome: "Nome do Sítio",
            //responsavel: "Nome do Responsável",
            CidadeId: "Cidade",
            EstadoId: "Estado",
            /*
                        RelevanciasitioId: "Relevância Sítio",
                        CategoriasitioId: "Categoria do Sitio",
                        ExposicaositioId: "Exposição do Sítio",
                        FormasitioId: "Forma do Sítio",
                        IntegridadesitioId: "Integridade do Sítio",
                        ArterupestreId: "Arte rupestre",
                        ContextodeposicaoId: "Contexto de Deposição",
                        DensidadematerialId: "Densidade do Material",
            */
            latitude: "Latitude Central",
            longitude: "Longitude Central",
        };
        Object.keys(fields).forEach((key, i) => !this.state[key] ? errors.push(<div key={i}>{fields[key]} não pode ser vazio.</div>) : null);

        /**
        const arrays = {
            Tipositios: "Tipo de Sítio",
            Atividades: "Atividade",
            Usoatuais: "Uso atual do terreno"
        };
        Object.keys(arrays).forEach((key, i) => !this.state[key].length ? errors.push(<div key={i}>{arrays[key]} deve conter ao menos um item.</div>) : null);
        **/




        if (errors.length) {
            this.context.addToast({ header: "Erro de Validação", body: errors });
            return;
        }

        const sitio = {
            ...this.state,
            datahora: this.state.datahora.toISOString(),
            Anexos: this.state.Anexos.map(anexo => ({
                ...anexo,
                datahora: anexo.datahora.toISOString()
            }))
        };

        const entry = this.props.index ? {
            index: this.props.index,
            sitio,
            responsavel: sitio.responsavel,
            nome: sitio.nome
        } : {
            sitio,
            responsavel: sitio.responsavel,
            nome: sitio.nome
        }

        IDB.put("Sitio", entry).then(result => {
            this.context.addToast({
                header: "Sucesso",
                body: "Alterações gravadas com sucesso!"
            });
            this.props.onSave(result);
        })
            .catch(error => {
                console.log(error);
                this.context.addToast({
                    header: "Erro",
                    body: "Erro na operação: " + error.message
                });
            });


    }

    toggleEstruturasitio(item) {
        this.setState(state => ({
            Estruturassitio: state.Estruturassitio.indexOf(item) < 0 ?
                [...state.Estruturassitio, item] :
                state.Estruturassitio.filter(e => e !== item)
        }));
    }

    toggleCorsolo(item) {
        this.setState(state => ({
            Coressolo: state.Coressolo.indexOf(item) < 0 ?
                [...state.Coressolo, item] :
                state.Coressolo.filter(e => e !== item)
        }));
    }

    toggleFatordestruicao(item) {
        this.setState(state => ({
            Fatoresdestruicao: state.Fatoresdestruicao.indexOf(item) < 0 ?
                [...state.Fatoresdestruicao, item] :
                state.Fatoresdestruicao.filter(e => e !== item)
        }));
    }

    toggleAtividade(item) {
        this.setState(state => ({
            Atividades: state.Atividades.indexOf(item) < 0 ?
                [...state.Atividades, item] :
                state.Atividades.filter(e => e !== item)
        }));
    }

    toggleTipositio(item) {
        this.setState(state => ({
            Tipositios: state.Tipositios.indexOf(item) < 0 ?
                [...state.Tipositios, item] :
                state.Tipositios.filter(e => e !== item)
        }));
    }

    toggleUsoatual(item) {
        this.setState(state => ({
            Usoatuais: state.Usoatuais.indexOf(item) < 0 ?
                [...state.Usoatuais, item] :
                state.Usoatuais.filter(e => e !== item)
        }));
    }

    toggleTiposolo(item) {
        this.setState(state => ({
            Tiposolos: state.Tiposolos.indexOf(item) < 0 ?
                [...state.Tiposolos, item] :
                state.Tiposolos.filter(e => e !== item)
        }));
    }

    toggleTipovegetacao(item) {
        this.setState(state => ({
            Tipovegetacoes: state.Tipovegetacoes.indexOf(item) < 0 ?
                [...state.Tipovegetacoes, item] :
                state.Tipovegetacoes.filter(e => e !== item)
        }));
    }

    onLocationError = error => {
        var message = "";
        switch (error.code) {
            case error.PERMISSION_DENIED:
                message = "Permissão de geolocalização negada pelo usuário. Acesse as configurações do seu dispositivo para liberar o recurso.";
                break;
            case error.POSITION_UNAVAILABLE:
                message = "Informação de geolocalização não disponível. Verifique se o GPS está ativo ou se possui conexão com a internet para uma localização aproximada.";
                break;
            case error.TIMEOUT:
                message = "Tempo limite expirado para obter a localização.";
                break;
            default:
                message = "Erro desconhecido ao obter a localização.";
                break;
        }
        this.context.addToast({ header: "Erro", body: message });
        this.setState({ locating: false });
    }

    getCoords() {
        return new Promise((resolve) => {
            const onLocationSuccess = position => {
                this.setState({ locating: false }, () => resolve(position.coords));
            }
            if (navigator.geolocation) {
                this.setState({ locating: true }, () => navigator.geolocation.getCurrentPosition(onLocationSuccess, this.onLocationError, { timeout: 15000 }));
            } else {
                this.context.addToast({ header: "Erro", body: "Dispositivo sem suporte a geolocalização." });
            }
        })
    }

    updateMeasures() {
        this.setState(state => {

            const corners = [
                { latitude: state.latitude_se, longitude: state.longitude_se },
                { latitude: state.latitude_sw, longitude: state.longitude_sw },
                { latitude: state.latitude_ne, longitude: state.longitude_ne },
                { latitude: state.latitude_nw, longitude: state.longitude_nw }
            ];

            const minLat = corners.reduce((min, corner) => corner.latitude < min.latitude ? corner : min, corners[0]);
            const minLng = corners.reduce((min, corner) => corner.longitude < min.longitude ? corner : min, corners[0]);
            const maxLat = corners.reduce((max, corner) => corner.latitude > max.latitude ? corner : max, corners[0]);
            const maxLng = corners.reduce((max, corner) => corner.longitude > max.longitude ? corner : max, corners[0]);


            return {
                area: geolib.getAreaOfPolygon(corners),
                largura: geolib.getDistance(
                    {
                        latitude: maxLng.latitude,
                        longitude: maxLng.longitude
                    },
                    {
                        latitude: minLng.latitude,
                        longitude: minLng.longitude
                    },
                ),
                comprimento: geolib.getDistance(
                    {
                        latitude: maxLat.latitude,
                        longitude: maxLat.longitude
                    },
                    {
                        latitude: minLat.latitude,
                        longitude: minLat.longitude
                    }
                )
            }
        })
    }

    setLatLngCorner(corner) {
        this.getCoords().then(coords => {
            const latitude = coords.latitude.toFixed(6);
            const longitude = coords.longitude.toFixed(6);
            this.setState({
                [`latitude_${corner}`]: latitude,
                [`longitude_${corner}`]: longitude
            }, () => this.updateMeasures());
        });
    }

    setLatLng(anexo) {
        this.getCoords().then(coords => {
            const latitude = coords.latitude.toFixed(6);
            const longitude = coords.longitude.toFixed(6);
            this.setState(state => ({ Anexos: state.Anexos.map((a, k) => anexo === a ? { ...a, latitude, longitude } : a) }))
        }).catch(error => {
            console.log(error);
            this.context.addToast({ header: "Erro", body: "Houve uma falha na geolocalização." });
        });
    }

    setLatLngCenter() {
        this.getCoords().then(coords => {
            const latitude = coords.latitude.toFixed(6);
            const longitude = coords.longitude.toFixed(6);
            const altitude = coords.altitude ?? 0;
            const precisao = parseInt(coords.accuracy ?? 0);
            this.setState({ latitude, longitude, altitude, precisao });
        });
    }

    addAnexos(anexos) {
        this.setState(state => ({
            Anexos: [...state.Anexos, ...anexos],
            activeKey: state.Anexos.length
        }));
    }

    delAnexo(anexo) {
        this.setState(state => ({
            Anexos: state.Anexos.filter(a => a !== anexo)
        }));
    }

    onChangeBlob(anexo, blob) {
        this.setState(state => ({
            Anexos: state.Anexos.map(a => a === anexo ? { ...a, blob } : a)
        }));
    }

    onChangeFile(anexo, file) {
        console.log(file);
        this.setState(state => ({
            Anexos: state.Anexos.map(a => a === anexo ? { ...a, ...file } : a)
        }));
    }

    componentDidMount() {
        if (!this.state.latitude && !this.state.longitude) {
            this.setLatLngCenter();
        }

        this.state.Anexos.filter(({ id, blob }) => id && !blob).forEach(anexo => {
            this.context.request("GET", `/anexo/${anexo.id}`, null, true)
                .then(response => response.blob())
                .then(blob => {
                    this.setState(state => ({ Anexos: state.Anexos.map(a => a === anexo ? { ...a, blob } : a) }))
                })
                .catch(error => {
                    console.log(error);
                    this.context.addToast({ header: "Erro", body: "Houve um erro no download das imagens: " + error.toString() });
                });
        });
    }



    render() {
        const {
            ProjetoId,
            EstadoId,
            CidadeId,
            TiporelevoId,
            PropriedadeterraId,
            ProtecaolegalId,
            CategoriasitioId,
            ArterupestreId,
            ExposicaositioId,
            FormasitioId,
            ContextodeposicaoId,
            DensidadematerialId,
            IntegridadesitioId,
            RelevanciasitioId,
            TexturasoloId,
            EstadosoloId,
            ModomedicaoId,
            MetodomedicaoId,
            OrgaoeditorId,
            DatumId,
            UnidadegeomorfologicaId,
            CompartimentotopograficoId,
            nome,
            numero,
            designacao_outras,
            descricao,
            latitude,
            longitude,
            altitude,
            precisao,
            latitude_nw,
            longitude_nw,
            latitude_ne,
            longitude_ne,
            latitude_se,
            longitude_se,
            latitude_sw,
            longitude_sw,
            comprimento,
            largura,
            altura_maxima,
            area,
            documento_cartografico,
            ano_edicao,
            escala,
            documentacao,
            bibliografia,
            localidade,
            localidade_outras,
            endereco,
            acesso,
            nome_proprietario,
            endereco_proprietario,
            cep_proprietario,
            email_proprietario,
            contato_proprietario,
            nome_ocupante,
            agua_proxima,
            distancia_agua,
            nome_rioproximo,
            bacia,
            sitio_relacionado,
            medidas_preservacao,
            possibilidade_destruicao,
            qtd_coletasolo,
            observacoes,
            atualizacoes,
            outros_artefatos,
            filiacao_cultural_litico_tradicoes,
            filiacao_cultural_litico_fases,
            filiacao_cultural_litico_complementos,
            filiacao_cultural_litico_outros,
            filiacao_cultural_ceramico_tradicoes,
            filiacao_cultural_ceramico_fases,
            filiacao_cultural_ceramico_complementos,
            filiacao_cultural_ceramico_outros,
            filiacao_cultural_rupestre_tradicoes,
            filiacao_cultural_rupestre_estilos,
            filiacao_cultural_rupestre_complementos,
            filiacao_cultural_rupestre_outros,
            datacao_absoluta,
            datacao_relativa,
            instituicoes_acervo,
            catalogos_acervo,
            outros_atividades,
            outros_usoatual,
            outros_estruturas,
            outros_fatores,
            instituicoes_guarda,
            datahora,
            nome_equipe,
            nome_responsavel,
            endereco_responsavel,
            cep_responsavel,
            email_responsavel,
            contato_responsavel,
            nome_instituicao,
            endereco_instituicao,
            cep_instituicao,
            email_instituicao,
            contato_instituicao,
            Coressolo,
            Estruturassitio,
            Fatoresdestruicao,
            Tipovegetacoes,
            Tiposolos,
            Tipositios,
            Atividades,
            Usoatuais,
            Materiais,
            Anexos
        } = this.state;

        return <Form onSubmit={event => this.submit(event)} className="text-light form-large">
            <h4 className="text-light d-flex align-items-center">
                <FontAwesomeIcon icon={faMapMarkedAlt} className="mr-2" />Cadastro de Sítio Arqueológico
                <div className="ms-auto text-nowrap">
                    <Button className="mr-2" variant="secondary" onClick={() => this.props.onCancel()}>
                        <FontAwesomeIcon icon={faTimes} /><span className="d-none d-md-inline">&nbsp;Cancelar</span>
                    </Button>
                    <Button type="submit" variant="primary">
                        <FontAwesomeIcon icon={faSave} /><span className="d-none d-md-inline">&nbsp;Salvar Rascunho</span>
                    </Button>
                </div>
            </h4>
            <hr />
            <Tabs onSelect={tabKey => this.setState({ tabKey })} activeKey={this.state.tabKey}>
                <Tab eventKey="Geral" title={<div title="Geral"><FontAwesomeIcon icon={faEdit} /><span className="d-none d-md-inline"> Geral</span></div>}>
                    <Row className="mt-3">
                        <Col md={12}><h4 className="text-light">Dados do Sítio</h4></Col>
                        <Col md={8}>
                            <Form.Group className="mb-2">
                                <Form.Label className={nome ? '' : 'label-required'}>Nome do Sítio</Form.Label>
                                <Form.Control value={nome} onChange={e => this.setState({ nome: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-2">
                                <Form.Label>Número CNSA</Form.Label>
                                <Form.Control value={numero} onChange={e => this.setState({ numero: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-2">
                                <Form.Label>Descrição do Sítio</Form.Label>
                                <Form.Control as="textarea" value={descricao} onChange={e => this.setState({ descricao: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-2">
                                <Form.Label>Outras designações</Form.Label>
                                <Form.Control value={designacao_outras} onChange={e => this.setState({ designacao_outras: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-2">
                                <Form.Label>Projeto</Form.Label>
                                <Form.Control as="select" value={ProjetoId?.toString() ?? ""} onChange={e => this.setState({ ProjetoId: e.target.value ? parseInt(e.target.value) : null })}>
                                    <option value="">Selecione...</option>
                                    {this.context.auxiliar.Projeto.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-2">
                                <Form.Label>Sítios relacionados</Form.Label>
                                <Form.Control value={sitio_relacionado} onChange={e => this.setState({ sitio_relacionado: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={12}><hr /><h4 className="text-light">Localização</h4></Col>
                        <Col md={4}>
                            <Form.Group className="mb-2">
                                <Form.Label className={EstadoId ? '' : 'label-required'}>Estado</Form.Label>
                                <Form.Control as="select" value={EstadoId?.toString() ?? ""} onChange={e => this.setState({ EstadoId: e.target.value ? parseInt(e.target.value) : null, CidadeId: null })}>
                                    <option value="">Selecione...</option>
                                    {this.context.auxiliar.Estado.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-2">
                                <Form.Label className={CidadeId ? '' : 'label-required'}>Cidade</Form.Label>
                                <Form.Control as="select" value={CidadeId?.toString() ?? ""} onChange={e => this.setState({ CidadeId: e.target.value ? parseInt(e.target.value) : null })}>
                                    <option value="">Selecione...</option>
                                    {this.context.auxiliar.Cidade.filter(c => c.EstadoId === EstadoId).map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-2">
                                <Form.Label>Localidade</Form.Label>
                                <Form.Control value={localidade} onChange={e => this.setState({ localidade: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-2">
                                <Form.Label>Outras designações da localidade</Form.Label>
                                <Form.Control value={localidade_outras} onChange={e => this.setState({ localidade_outras: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-2">
                                <Form.Label>Endereço</Form.Label>
                                <Form.Control as="textarea" value={endereco} onChange={e => this.setState({ endereco: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-2">
                                <Form.Label>Acesso</Form.Label>
                                <Form.Control as="textarea" value={acesso} onChange={e => this.setState({ acesso: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={12}><hr /><h4 className="text-light">Dados da Propriedade</h4></Col>
                        <Col md={8}>
                            <Form.Group className="mb-2">
                                <Form.Label>Nome do Proprietário</Form.Label>
                                <Form.Control value={nome_proprietario} onChange={e => this.setState({ nome_proprietario: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-2">
                                <Form.Label>Contato do Proprietário</Form.Label>
                                <Form.Control value={contato_proprietario} onChange={e => this.setState({ contato_proprietario: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-2">
                                <Form.Label>Endereço do Proprietário</Form.Label>
                                <Form.Control as="textarea" value={endereco_proprietario} onChange={e => this.setState({ endereco_proprietario: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-2">
                                <Form.Label>CEP do Proprietário</Form.Label>
                                <Form.Control value={cep_proprietario} onChange={e => this.setState({ cep_proprietario: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={8}>
                            <Form.Group className="mb-2">
                                <Form.Label>Email do Proprietário</Form.Label>
                                <Form.Control type="email" value={email_proprietario} onChange={e => this.setState({ email_proprietario: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-2">
                                <Form.Label>Nome do Ocupante Atual</Form.Label>
                                <Form.Control value={nome_ocupante} onChange={e => this.setState({ nome_ocupante: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-2">
                                <Form.Label>Propriedade da Terra</Form.Label>
                                <Form.Control as="select" value={PropriedadeterraId?.toString() ?? ""} onChange={e => this.setState({ PropriedadeterraId: e.target.value ? parseInt(e.target.value) : null })}>
                                    <option value="">Selecione...</option>
                                    {this.context.auxiliar.Propriedadeterra.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={8}>
                            <Form.Group className="mb-2">
                                <Form.Label>Proteção Legal</Form.Label>
                                <Form.Control as="select" value={ProtecaolegalId?.toString() ?? ""} onChange={e => this.setState({ ProtecaolegalId: e.target.value ? parseInt(e.target.value) : null })}>
                                    <option value="">Selecione...</option>
                                    {this.context.auxiliar.Protecaolegal.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md="3" xs="4">
                            <Button variant="secondary" className="w-100" onClick={() => this.context.setContent(<SitioList />)}>
                                <FontAwesomeIcon icon={faTimes} />
                                <span className="d-none d-md-inline"> Cancelar</span>
                            </Button>
                        </Col>
                        <Col xs={{ offset: 4, span: 4 }} md={{ offset: 6, span: 3 }}>
                            <Button variant="info" className="w-100" onClick={() => this.setState({ tabKey: "Tipologia" }, () => this.context.scrollToTop())}>
                                <span className="d-none d-md-inline">Avançar </span><FontAwesomeIcon icon={faFastForward} />
                            </Button>
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="Tipologia" title={<div title="Tipologia"><FontAwesomeIcon icon={faListCheck} /><span className="d-none d-md-inline"> Descrição</span></div>}>
                    <Row className="mt-3">                        
                        <Col md={12}><h4 className="text-light">Categorização</h4></Col>
                        <Col md={4}>
                            <Form.Group className="mb-2">
                                <Form.Label className={RelevanciasitioId ? '' : ''}>Relevância do Sítio</Form.Label>
                                <Form.Control as="select" value={RelevanciasitioId?.toString() ?? ""} onChange={e => this.setState({ RelevanciasitioId: e.target.value ? parseInt(e.target.value) : null })}>
                                    <option value="">Selecione...</option>
                                    {this.context.auxiliar.Relevanciasitio.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-2">
                                <Form.Label className={CategoriasitioId ? '' : ''}>Categoria do Sítio</Form.Label>
                                <Form.Control as="select" value={CategoriasitioId?.toString() ?? ""} onChange={e => this.setState({ CategoriasitioId: e.target.value ? parseInt(e.target.value) : null })}>
                                    <option value="">Selecione...</option>
                                    {this.context.auxiliar.Categoriasitio.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-2">
                                <Form.Label className={ExposicaositioId ? '' : ''}>Exposição do Sítio</Form.Label>
                                <Form.Control as="select" value={ExposicaositioId?.toString() ?? ""} onChange={e => this.setState({ ExposicaositioId: e.target.value ? parseInt(e.target.value) : null })}>
                                    <option value="">Selecione...</option>
                                    {this.context.auxiliar.Exposicaositio.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-2">
                                <Form.Label className={FormasitioId ? '' : ''}>Forma do Sítio</Form.Label>
                                <Form.Control as="select" value={FormasitioId?.toString() ?? ""} onChange={e => this.setState({ FormasitioId: e.target.value ? parseInt(e.target.value) : null })}>
                                    <option value="">Selecione...</option>
                                    {this.context.auxiliar.Formasitio.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-2">
                                <Form.Label className={IntegridadesitioId ? '' : ''}>Integridade do Sítio</Form.Label>
                                <Form.Control as="select" value={IntegridadesitioId?.toString() ?? ""} onChange={e => this.setState({ IntegridadesitioId: e.target.value ? parseInt(e.target.value) : null })}>
                                    <option value="">Selecione...</option>
                                    {this.context.auxiliar.Integridadesitio.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-2">
                                <Form.Label className={ArterupestreId ? '' : ''}>Arte Rupestre</Form.Label>
                                <Form.Control as="select" value={ArterupestreId?.toString() ?? ""} onChange={e => this.setState({ ArterupestreId: e.target.value ? parseInt(e.target.value) : null })}>
                                    <option value="">Selecione...</option>
                                    {this.context.auxiliar.Arterupestre.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-2">
                                <Form.Label className={ContextodeposicaoId ? '' : ''}>Contexto de Deposição</Form.Label>
                                <Form.Control as="select" value={ContextodeposicaoId?.toString() ?? ""} onChange={e => this.setState({ ContextodeposicaoId: e.target.value ? parseInt(e.target.value) : null })}>
                                    <option value="">Selecione...</option>
                                    {this.context.auxiliar.Contextodeposicao.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-2">
                                <Form.Label className={DensidadematerialId ? '' : ''}>Densidade do material</Form.Label>
                                <Form.Control as="select" value={DensidadematerialId?.toString() ?? ""} onChange={e => this.setState({ DensidadematerialId: e.target.value ? parseInt(e.target.value) : null })}>
                                    <option value="">Selecione...</option>
                                    {this.context.auxiliar.Densidadematerial.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={12}><hr /><h4 className="text-light">Tabelas</h4></Col>
                        <Col md={4}>
                            <Table striped size="sm" hover className="mb-0">
                                <thead>
                                    <tr>
                                        <th colSpan={4} className="text-center">
                                            <Form.Label className={Tipositios.length ? '' : ''}>
                                                Tipo de Sítio
                                            </Form.Label>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th><input type="checkbox" readOnly checked={false} /></th>
                                        <th>Tipo</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {this.context.auxiliar.Tipositio.map((item, key) =>
                                        <tr role="button" key={key} onClick={() => this.toggleTipositio(item)}>
                                            <td style={{ width: '1%' }}><input readOnly type="checkbox" checked={Tipositios.indexOf(item) > -1} /></td>
                                            <td>{item.nome}</td>
                                        </tr>)}
                                </tbody>
                            </Table>
                        </Col>
                        <Col md={4}>
                            <Table striped hover size="sm" className="mb-0">
                                <thead>
                                    <tr>
                                        <th colSpan={4} className="text-center">
                                            <Form.Label className={Atividades.length ? '' : ''}>
                                                Atividades Realizadas
                                            </Form.Label>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th><input type="checkbox" readOnly checked={false} /></th>
                                        <th>Atividade</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {this.context.auxiliar.Atividade.map((item, key) =>
                                        <tr role="button" key={key} onClick={() => this.toggleAtividade(item)}>
                                            <td style={{ width: '1%' }}><input readOnly type="checkbox" checked={Atividades.indexOf(item) > -1} /></td>
                                            <td>{item.nome}</td>
                                        </tr>)}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={2}>
                                            <Form.Label>Outras atividades</Form.Label>
                                            <Form.Control value={outros_atividades} onChange={e => this.setState({ outros_atividades: e.target.value })} />
                                        </td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                        <Col md={4}>
                            <Table striped hover size="sm" className="mb-0">
                                <thead>
                                    <tr>
                                        <th colSpan={4} className="text-center">
                                            <Form.Label className={Usoatuais.length ? '' : ''}>
                                                Uso atual do Terreno
                                            </Form.Label>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th><input type="checkbox" readOnly checked={false} /></th>
                                        <th>Tipo</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {this.context.auxiliar.Usoatual.map((item, key) =>
                                        <tr role="button" key={key} onClick={() => this.toggleUsoatual(item)}>
                                            <td style={{ width: '1%' }}><input readOnly type="checkbox" checked={Usoatuais.indexOf(item) > -1} /></td>
                                            <td>{item.nome}</td>
                                        </tr>)}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={2}>
                                            <Form.Label>Outros usos</Form.Label>
                                            <Form.Control value={outros_usoatual} onChange={e => this.setState({ outros_usoatual: e.target.value })} />
                                        </td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md="3" xs="4">
                            <Button variant="secondary" className="w-100" onClick={() => this.context.setContent(<SitioList />)}>
                                <FontAwesomeIcon icon={faTimes} />
                                <span className="d-none d-md-inline"> Cancelar</span>
                            </Button>
                        </Col>
                        <Col md={{ offset: 3, span: 3 }} xs="4">
                            <Button variant="info" className="w-100" onClick={() => this.setState({ tabKey: "Geral" }, () => this.context.scrollToTop())}>
                                <FontAwesomeIcon icon={faFastBackward} /><span className="d-none d-md-inline"> Voltar</span>
                            </Button>
                        </Col>
                        <Col md="3" xs="4">
                            <Button variant="info" className="w-100" onClick={() => this.setState({ tabKey: "Paisagem" }, () => this.context.scrollToTop())}>
                                <span className="d-none d-md-inline">Avançar </span><FontAwesomeIcon icon={faFastForward} />
                            </Button>
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="Paisagem" title={<div title="Paisagem"><FontAwesomeIcon icon={faMountainSun} /><span className="d-none d-md-inline"> Paisagem</span></div>}>
                    <Row className="mt-3">
                        <Col md={12}><h4 className="text-light">Geografia</h4></Col>
                        <Col md={3}>
                            <Form.Group className="mb-2">
                                <Form.Label>Água mais próxima</Form.Label>
                                <Form.Control value={agua_proxima} onChange={e => this.setState({ agua_proxima: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group className="mb-2">
                                <Form.Label>Distância à água</Form.Label>
                                <Form.Control value={distancia_agua} onChange={e => this.setState({ distancia_agua: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group className="mb-2">
                                <Form.Label>Rio mais próximo</Form.Label>
                                <Form.Control value={nome_rioproximo} onChange={e => this.setState({ nome_rioproximo: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group className="mb-2">
                                <Form.Label>Bacia Hidrográfica</Form.Label>
                                <Form.Control value={bacia} onChange={e => this.setState({ bacia: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-2">
                                <Form.Label>Textura do solo</Form.Label>
                                <Form.Control as="select" value={TexturasoloId?.toString() ?? ""} onChange={e => this.setState({ TexturasoloId: e.target.value ? parseInt(e.target.value) : null })}>
                                    <option value="">Selecione...</option>
                                    {this.context.auxiliar.Texturasolo.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-2">
                                <Form.Label>Estado do solo</Form.Label>
                                <Form.Control as="select" value={EstadosoloId?.toString() ?? ""} onChange={e => this.setState({ EstadosoloId: e.target.value ? parseInt(e.target.value) : null })}>
                                    <option value="">Selecione...</option>
                                    {this.context.auxiliar.Estadosolo.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-2">
                                <Form.Label>Tipo do relevo</Form.Label>
                                <Form.Control as="select" value={TiporelevoId?.toString() ?? ""} onChange={e => this.setState({ TiporelevoId: e.target.value ? parseInt(e.target.value) : null })}>
                                    <option value="">Selecione...</option>
                                    {this.context.auxiliar.Tiporelevo.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-2">
                                <Form.Label>Unidade Geomorfológica</Form.Label>
                                <Form.Control as="select" value={UnidadegeomorfologicaId?.toString() ?? ""} onChange={e => this.setState({ UnidadegeomorfologicaId: e.target.value ? parseInt(e.target.value) : null })}>
                                    <option value="">Selecione...</option>
                                    {this.context.auxiliar.Unidadegeomorfologica.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-2">
                                <Form.Label>Compartimento Topográfico</Form.Label>
                                <Form.Control as="select" value={CompartimentotopograficoId?.toString() ?? ""} onChange={e => this.setState({ CompartimentotopograficoId: e.target.value ? parseInt(e.target.value) : null })}>
                                    <option value="">Selecione...</option>
                                    {this.context.auxiliar.Compartimentotopografico.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={4} style={{ maxHeight: 530, overflowY: "scroll" }}>
                            <Table striped hover size="sm" className="mb-0">
                                <thead>
                                    <tr>
                                        <th colSpan={4} className="text-center">Cores do solo</th>
                                    </tr>
                                    <tr>
                                        <th colSpan={4}>
                                            <Form.Control value={this.state.buscaCor} onChange={e => this.setState({ buscaCor: e.target.value })} />
                                        </th>
                                    </tr>
                                    <tr>
                                        <th><input type="checkbox" readOnly checked={false} /></th>
                                        <th>Código Munsell</th>
                                        <th>RGB</th>
                                        <th style={{ width: "1%" }}>Cor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {console.log(this.context.auxiliar.Corsolo)}
                                    {this.context.auxiliar.Corsolo
                                        .filter(cor => cor.nome.includes(this.state.buscaCor) || Coressolo.indexOf(cor) > -1)
                                        .sort((a, b) => Coressolo.indexOf(a) > -1 ? (Coressolo.indexOf(b) > -1 ? a.id - b.id : -1) : (Coressolo.indexOf(b) > -1 ? 1 : a.id - b.id))
                                        .slice(0, 20).map((cor, key) =>
                                            <tr role="button" key={key} onClick={() => this.toggleCorsolo(cor)}>
                                                <td style={{ width: '1%' }}><input readOnly type="checkbox" checked={Coressolo.indexOf(cor) > -1} /></td>
                                                <td>{cor.nome}</td>
                                                <td>{cor.rgb}</td>
                                                <td style={{ width: "1%" }}>
                                                    <div style={{ width: 40, height: 40, backgroundColor: cor.rgb }}></div>
                                                </td>
                                            </tr>)}
                                </tbody>
                            </Table>
                        </Col>
                        <Col md={4}>
                            <Table striped hover size="sm" className="mb-0">
                                <thead>
                                    <tr>
                                        <th colSpan={4} className="text-center">Tipos de Solo</th>
                                    </tr>
                                    <tr>
                                        <th><input type="checkbox" readOnly checked={false} /></th>
                                        <th>Tipo</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {this.context.auxiliar.Tiposolo.map((item, key) =>
                                        <tr role="button" key={key} onClick={() => this.toggleTiposolo(item)}>
                                            <td style={{ width: '1%' }}><input readOnly type="checkbox" checked={Tiposolos.indexOf(item) > -1} /></td>
                                            <td>{item.nome}</td>
                                        </tr>)}
                                </tbody>
                            </Table>
                        </Col>
                        <Col md={4}>
                            <Table striped hover size="sm" className="mb-0">
                                <thead>
                                    <tr>
                                        <th colSpan={4} className="text-center">Tipos de Vegetação</th>
                                    </tr>
                                    <tr>
                                        <th><input type="checkbox" readOnly checked={false} /></th>
                                        <th>Tipo</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {this.context.auxiliar.Tipovegetacao.map((item, key) =>
                                        <tr role="button" key={key} onClick={() => this.toggleTipovegetacao(item)}>
                                            <td style={{ width: '1%' }}><input readOnly type="checkbox" checked={Tipovegetacoes.indexOf(item) > -1} /></td>
                                            <td>{item.nome}</td>
                                        </tr>)}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md="3" xs="4">
                            <Button variant="secondary" className="w-100" onClick={() => this.context.setContent(<SitioList />)}>
                                <FontAwesomeIcon icon={faTimes} />
                                <span className="d-none d-md-inline"> Cancelar</span>
                            </Button>
                        </Col>
                        <Col md={{ offset: 3, span: 3 }} xs="4">
                            <Button variant="info" className="w-100" onClick={() => this.setState({ tabKey: "Tipologia" }, () => this.context.scrollToTop())}>
                                <FontAwesomeIcon icon={faFastBackward} /><span className="d-none d-md-inline"> Voltar</span>
                            </Button>
                        </Col>
                        <Col md="3" xs="4">
                            <Button variant="info" className="w-100" onClick={() => this.setState({ tabKey: "Georreferenciamento" }, () => this.context.scrollToTop())}>
                                <span className="d-none d-md-inline">Avançar </span><FontAwesomeIcon icon={faFastForward} />
                            </Button>
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="Georreferenciamento" title={<div title="Georreferenciamento"><FontAwesomeIcon icon={faMapMarkerAlt} /><span className="d-none d-md-inline"> Georreferenciamento</span></div>}>
                    <Row className="mt-3 d-flex align-items-stretch">
                        <Col md={4} className="flex-grow-1 flex-shrink-1 mb-2" style={{ minHeight: 420 }}>
                            <MapWithMarker
                                corners={[
                                    { lat: latitude_ne, lng: longitude_ne },
                                    { lat: latitude_nw, lng: longitude_nw },
                                    { lat: latitude_sw, lng: longitude_sw },
                                    { lat: latitude_se, lng: longitude_se },
                                ]}
                                center={{ lat: latitude, lng: longitude }}
                                mutable={this.state.tabKey} />
                        </Col>
                        <Col md={8}>
                            <Row>
                                <Col md={12}><h4 className="text-light">Coordenadas</h4></Col>
                                <Col md={6}>
                                    <Form.Label className={latitude && longitude ? '' : 'label-required'}>Ponto Central</Form.Label>
                                    <InputGroup className="mb-2">
                                        <Form.Control type="number" value={latitude} onChange={e => this.setState({ latitude: e.target.value })} />
                                        <Form.Control type="number" value={longitude} onChange={e => this.setState({ longitude: e.target.value })} />
                                        <Button disabled={this.state.locating} variant="info" onClick={() => this.setLatLngCenter()}>
                                            {!this.state.locating ?
                                                <FontAwesomeIcon icon={faLocationDot} /> :
                                                <FontAwesomeIcon icon={faSpinner} spin />}
                                        </Button>
                                    </InputGroup>
                                    <Row>
                                        <Col className="mb-2">
                                            <Form.Label>Altitude (m)</Form.Label>
                                            <Form.Control type="number" value={altitude} onChange={e => this.setState({ altitude: e.target.value })} />
                                        </Col>
                                        <Col className="mb-2">
                                            <Form.Label>Altura (m)</Form.Label>
                                            <Form.Control type="number" value={altura_maxima} onChange={e => this.setState({ altura_maxima: e.target.value })} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-2">
                                            <Form.Label>Largura (m)</Form.Label>
                                            <Form.Control type="number" value={largura} onChange={e => this.setState({ largura: e.target.value })} />
                                        </Col>
                                        <Col className="mb-2">
                                            <Form.Label>Comprimento (m)</Form.Label>
                                            <Form.Control type="number" value={comprimento} onChange={e => this.setState({ comprimento: e.target.value })} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-2">
                                            <Form.Label>Precisão (m)</Form.Label>
                                            <Form.Control type="number" value={precisao} onChange={e => this.setState({ precisao: e.target.value })} />
                                        </Col>
                                        <Col className="mb-2">
                                            <Form.Label>Área (m²)</Form.Label>
                                            <Form.Control type="number" value={area} onChange={e => this.setState({ area: e.target.value })} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Canto Nordeste (NE)</Form.Label>
                                    <InputGroup className="mb-2">
                                        <Form.Control type="number" step={0.000001} value={latitude_ne} onChange={e => this.setState({ latitude_ne: e.target.value }, () => this.updateMeasures())} />
                                        <Form.Control type="number" step={0.000001} value={longitude_ne} onChange={e => this.setState({ longitude_ne: e.target.value }, () => this.updateMeasures())} />
                                        <Button disabled={this.state.locating} variant="info" onClick={() => this.setLatLngCorner("ne")}>
                                            {!this.state.locating ?
                                                <FontAwesomeIcon icon={faLocationDot} /> :
                                                <FontAwesomeIcon icon={faSpinner} spin />}
                                        </Button>
                                    </InputGroup>

                                    <Form.Label>Canto Sudeste (SE)</Form.Label>
                                    <InputGroup className="mb-2">
                                        <Form.Control type="number" step={0.000001} value={latitude_se} onChange={e => this.setState({ latitude_se: e.target.value }, () => this.updateMeasures())} />
                                        <Form.Control type="number" step={0.000001} value={longitude_se} onChange={e => this.setState({ longitude_se: e.target.value }, () => this.updateMeasures())} />
                                        <Button disabled={this.state.locating} variant="info" onClick={() => this.setLatLngCorner("se")}>
                                            {!this.state.locating ?
                                                <FontAwesomeIcon icon={faLocationDot} /> :
                                                <FontAwesomeIcon icon={faSpinner} spin />}
                                        </Button>
                                    </InputGroup>

                                    <Form.Label>Canto Sudoeste (SW)</Form.Label>
                                    <InputGroup className="mb-2">
                                        <Form.Control type="number" step={0.000001} value={latitude_sw} onChange={e => this.setState({ latitude_sw: e.target.value }, () => this.updateMeasures())} />
                                        <Form.Control type="number" step={0.000001} value={longitude_sw} onChange={e => this.setState({ longitude_sw: e.target.value }, () => this.updateMeasures())} />
                                        <Button disabled={this.state.locating} variant="info" onClick={() => this.setLatLngCorner("sw")}>
                                            {!this.state.locating ?
                                                <FontAwesomeIcon icon={faLocationDot} /> :
                                                <FontAwesomeIcon icon={faSpinner} spin />}
                                        </Button>
                                    </InputGroup>

                                    <Form.Label>Canto Noroeste (NW)</Form.Label>
                                    <InputGroup className="mb-2">
                                        <Form.Control type="number" step={0.000001} value={latitude_nw} onChange={e => this.setState({ latitude_nw: e.target.value }, () => this.updateMeasures())} />
                                        <Form.Control type="number" step={0.000001} value={longitude_nw} onChange={e => this.setState({ longitude_nw: e.target.value }, () => this.updateMeasures())} />
                                        <Button disabled={this.state.locating} variant="info" onClick={() => this.setLatLngCorner("nw")}>
                                            {!this.state.locating ?
                                                <FontAwesomeIcon icon={faLocationDot} /> :
                                                <FontAwesomeIcon icon={faSpinner} spin />}
                                        </Button>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}><hr /><h4 className="text-light">Cartografia</h4></Col>
                                <Col md={6} className="mb-2">
                                    <Form.Label>Documento Cartográfico</Form.Label>
                                    <Form.Control type="text" value={documento_cartografico} onChange={e => this.setState({ documento_cartografico: e.target.value })} />
                                </Col>
                                <Col md={3} className="mb-2">
                                    <Form.Label>Órgão Editor</Form.Label>
                                    <Form.Control as="select" value={OrgaoeditorId?.toString() ?? ""} onChange={e => this.setState({ OrgaoeditorId: e.target.value ? parseInt(e.target.value) : null })}>
                                        <option value="">Selecione...</option>
                                        {this.context.auxiliar.Orgaoeditor.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                    </Form.Control>
                                </Col>
                                <Col md={3} className="mb-2">
                                    <Form.Label>Ano de Edição</Form.Label>
                                    <Form.Control type="number" step={1} value={ano_edicao} onChange={e => this.setState({ ano_edicao: e.target.value })} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3} className="mb-2">
                                    <Form.Label>Escala</Form.Label>
                                    <Form.Control type="text" value={escala} onChange={e => this.setState({ escala: e.target.value })} />
                                </Col>
                                <Col md={9} className="mb-2">
                                    <Form.Label>Modo de Aferição</Form.Label>
                                    <Form.Control as="select" value={ModomedicaoId?.toString() ?? ""} onChange={e => this.setState({ ModomedicaoId: e.target.value ? parseInt(e.target.value) : null })}>
                                        <option value="">Selecione...</option>
                                        {this.context.auxiliar.Modomedicao.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Label>Método de Medição</Form.Label>
                                    <Form.Control as="select" value={MetodomedicaoId?.toString() ?? ""} onChange={e => this.setState({ MetodomedicaoId: e.target.value ? parseInt(e.target.value) : null })}>
                                        <option value="">Selecione...</option>
                                        {this.context.auxiliar.Metodomedicao.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                    </Form.Control>
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Datum</Form.Label>
                                    <Form.Control as="select" value={DatumId?.toString() ?? ""} onChange={e => this.setState({ DatumId: e.target.value ? parseInt(e.target.value) : null })}>
                                        <option value="">Selecione...</option>
                                        {this.context.auxiliar.Datum.map((item, key) => <option key={key} value={item.id}>{item.nome}</option>)}
                                    </Form.Control>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md="3" xs="4">
                            <Button variant="secondary" className="w-100" onClick={() => this.context.setContent(<SitioList />)}>
                                <FontAwesomeIcon icon={faTimes} />
                                <span className="d-none d-md-inline"> Cancelar</span>
                            </Button>
                        </Col>
                        <Col md={{ offset: 3, span: 3 }} xs="4">
                            <Button variant="info" className="w-100" onClick={() => this.setState({ tabKey: "Paisagem" }, () => this.context.scrollToTop())}>
                                <FontAwesomeIcon icon={faFastBackward} /><span className="d-none d-md-inline"> Voltar</span>
                            </Button>
                        </Col>
                        <Col md="3" xs="4">
                            <Button variant="info" className="w-100" onClick={() => this.setState({ tabKey: "Estruturas" }, () => this.context.scrollToTop())}>
                                <span className="d-none d-md-inline">Avançar </span><FontAwesomeIcon icon={faFastForward} />
                            </Button>
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="Estruturas" title={<div title="Estruturas"><FontAwesomeIcon icon={faLandmark} /><span className="d-none d-md-inline"> Estruturas</span></div>}>
                    <Row className="mt-3">
                        <Col md={6}>
                            <Table striped hover size="sm" className="mb-0">
                                <thead>
                                    <tr>
                                        <th colSpan={4} className="text-center">Estruturas do Sítio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.context.auxiliar.Estruturasitio.map((item, key) =>
                                        <tr role="button" key={key} onClick={() => this.toggleEstruturasitio(item)}>
                                            <td style={{ width: '1%' }}><input readOnly type="checkbox" checked={Estruturassitio.indexOf(item) > -1} /></td>
                                            <td>{item.nome}</td>
                                        </tr>)}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={3}>
                                            <Form.Group>
                                                <Form.Label>Outras Estruturas</Form.Label>
                                                <Form.Control as="textarea" value={outros_estruturas} onChange={e => this.setState({ outros_estruturas: e.target.value })} />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                        <Col md={6}>
                            <Table striped hover size="sm" className="mb-0">
                                <thead>
                                    <tr>
                                        <th colSpan={4} className="text-center">Fatores de Destruição</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.context.auxiliar.Fatordestruicao.map((item, key) =>
                                        <tr role="button" key={key} onClick={() => this.toggleFatordestruicao(item)}>
                                            <td style={{ width: '1%' }}><input readOnly type="checkbox" checked={Fatoresdestruicao.indexOf(item) > -1} /></td>
                                            <td>{item.nome}</td>
                                        </tr>)}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={3}>
                                            <Form.Group>
                                                <Form.Label>Outros Fatores</Form.Label>
                                                <Form.Control as="textarea" value={outros_fatores} onChange={e => this.setState({ outros_fatores: e.target.value })} />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                </tfoot>
                            </Table>
                            <Row className="mt-3">
                                <Col md={12}>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Possibilidades de Destruição</Form.Label>
                                        <Form.Control as="textarea" value={possibilidade_destruicao} onChange={e => this.setState({ possibilidade_destruicao: e.target.value })} />
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Medidas de preservação tomadas</Form.Label>
                                        <Form.Control as="textarea" value={medidas_preservacao} onChange={e => this.setState({ medidas_preservacao: e.target.value })} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md="3" xs="4">
                            <Button variant="secondary" className="w-100" onClick={() => this.context.setContent(<SitioList />)}>
                                <FontAwesomeIcon icon={faTimes} />
                                <span className="d-none d-md-inline"> Cancelar</span>
                            </Button>
                        </Col>
                        <Col md={{ offset: 3, span: 3 }} xs="4">
                            <Button variant="info" className="w-100" onClick={() => this.setState({ tabKey: "Georreferenciamento" }, () => this.context.scrollToTop())}>
                                <FontAwesomeIcon icon={faFastBackward} /><span className="d-none d-md-inline"> Voltar</span>
                            </Button>
                        </Col>
                        <Col md="3" xs="4">
                            <Button variant="info" className="w-100" onClick={() => this.setState({ tabKey: "Laboratório" }, () => this.context.scrollToTop())}>
                                <span className="d-none d-md-inline">Avançar </span><FontAwesomeIcon icon={faFastForward} />
                            </Button>
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="Laboratório" title={<div title="Laboratório"><FontAwesomeIcon icon={faMicroscope} /><span className="d-none d-md-inline"> Laboratório</span></div>}>
                    <Row className="mt-3">
                        <Col md={12}><h4 className="text-light">Materiais coletados para Laboratório</h4></Col>
                        <Col md={6} className="mb-2">
                            <Table striped hover size="sm" className="mb-0">
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Tipo</th>
                                        <th style={{ width: "1%" }}>Quantidade</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Materiais.map((material, key) => {
                                        return <tr role="button" key={key}>
                                            <td>{material.nome}</td>
                                            <td>{material.Tipomaterial.nome}</td>
                                            <td style={{ width: "1%" }}>
                                                <Form.Control type="number" min={0} step={1} value={material.SitioMaterial.quantidade} onChange={e => this.setState(state => ({ Materiais: state.Materiais.map(m => m !== material ? m : { ...material, SitioMaterial: { quantidade: e.target.value ? parseInt(e.target.value) : 0 } }) }))} />
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={3}>
                                            <Form.Group>
                                                <Form.Label>Outros Artefatos</Form.Label>
                                                <Form.Control as="textarea" value={outros_artefatos} onChange={e => this.setState({ outros_artefatos: e.target.value })} />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3}>
                                            <Form.Group className="mb-2">
                                                <Form.Label>Instituições de Guarda</Form.Label>
                                                <Form.Control as="textarea" value={instituicoes_guarda} onChange={e => this.setState({ instituicoes_guarda: e.target.value })} />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                        <Col md={6} className="mb-2">
                            <Row>
                                <Col md={12}>
                                    <h4 className="text-white">Filiações Culturais</h4>

                                    <hr />
                                </Col>
                                <Col md={12}>
                                    <h5 className="text-white">Artefatos Líticos</h5>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Tradições</Form.Label>
                                        <Form.Control value={filiacao_cultural_litico_tradicoes} onChange={e => this.setState({ filiacao_cultural_litico_tradicoes: e.target.value })} />
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Fases</Form.Label>
                                        <Form.Control value={filiacao_cultural_litico_fases} onChange={e => this.setState({ filiacao_cultural_litico_fases: e.target.value })} />
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Complementos</Form.Label>
                                        <Form.Control value={filiacao_cultural_litico_complementos} onChange={e => this.setState({ filiacao_cultural_litico_complementos: e.target.value })} />
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Outras Atribuições</Form.Label>
                                        <Form.Control value={filiacao_cultural_litico_outros} onChange={e => this.setState({ filiacao_cultural_litico_outros: e.target.value })} />
                                    </Form.Group>
                                </Col>
                                <Col md={12}><hr /><h5 className="text-light">Artefatos Cerâmicos</h5></Col>
                                <Col md={12}>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Tradições</Form.Label>
                                        <Form.Control value={filiacao_cultural_ceramico_tradicoes} onChange={e => this.setState({ filiacao_cultural_ceramico_tradicoes: e.target.value })} />
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Fases</Form.Label>
                                        <Form.Control value={filiacao_cultural_ceramico_fases} onChange={e => this.setState({ filiacao_cultural_ceramico_fases: e.target.value })} />
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Complementos</Form.Label>
                                        <Form.Control value={filiacao_cultural_ceramico_complementos} onChange={e => this.setState({ filiacao_cultural_ceramico_complementos: e.target.value })} />
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Outras Atribuições</Form.Label>
                                        <Form.Control value={filiacao_cultural_ceramico_outros} onChange={e => this.setState({ filiacao_cultural_ceramico_outros: e.target.value })} />
                                    </Form.Group>
                                </Col>
                                <Col md={12}><hr /><h5 className="text-light">Artefatos Rupestres</h5></Col>
                                <Col md={12}>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Tradições</Form.Label>
                                        <Form.Control value={filiacao_cultural_rupestre_tradicoes} onChange={e => this.setState({ filiacao_cultural_rupestre_tradicoes: e.target.value })} />
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Estilos</Form.Label>
                                        <Form.Control value={filiacao_cultural_rupestre_estilos} onChange={e => this.setState({ filiacao_cultural_rupestre_estilos: e.target.value })} />
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Complementos</Form.Label>
                                        <Form.Control value={filiacao_cultural_rupestre_complementos} onChange={e => this.setState({ filiacao_cultural_rupestre_complementos: e.target.value })} />
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Outras Atribuições</Form.Label>
                                        <Form.Control value={filiacao_cultural_rupestre_outros} onChange={e => this.setState({ filiacao_cultural_rupestre_outros: e.target.value })} />
                                    </Form.Group>
                                </Col>
                                <Col md={12}><hr /><h5 className="text-light">Guarda do Acervo</h5></Col>
                                <Col md={12}>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Instituições de Acervo</Form.Label>
                                        <Form.Control as="textarea" value={instituicoes_acervo} onChange={e => this.setState({ instituicoes_acervo: e.target.value })} />
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Catálogos do Acervo</Form.Label>
                                        <Form.Control as="textarea" value={catalogos_acervo} onChange={e => this.setState({ catalogos_acervo: e.target.value })} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Sacos coletados</Form.Label>
                                        <Form.Control type="number" min={0} step={1} value={qtd_coletasolo} onChange={e => this.setState({ qtd_coletasolo: e.target.value ? parseInt(e.target.value) : 0 })} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Datação Absoluta</Form.Label>
                                        <Form.Control type="text" value={datacao_absoluta} onChange={e => this.setState({ datacao_absoluta: e.target.value })} />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Datação Relativa</Form.Label>
                                        <Form.Control type="text" value={datacao_relativa} onChange={e => this.setState({ datacao_relativa: e.target.value })} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md="3" xs="4">
                            <Button variant="secondary" className="w-100" onClick={() => this.context.setContent(<SitioList />)}>
                                <FontAwesomeIcon icon={faTimes} />
                                <span className="d-none d-md-inline"> Cancelar</span>
                            </Button>
                        </Col>
                        <Col md={{ offset: 3, span: 3 }} xs="4">
                            <Button variant="info" className="w-100" onClick={() => this.setState({ tabKey: "Estruturas" }, () => this.context.scrollToTop())}>
                                <FontAwesomeIcon icon={faFastBackward} /><span className="d-none d-md-inline"> Voltar</span>
                            </Button>
                        </Col>
                        <Col md="3" xs="4">
                            <Button variant="info" className="w-100" onClick={() => this.setState({ tabKey: "Imagens" }, () => this.context.scrollToTop())}>
                                <span className="d-none d-md-inline">Avançar </span><FontAwesomeIcon icon={faFastForward} />
                            </Button>
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="Imagens" title={<div title="Imagens"><FontAwesomeIcon icon={faImage} /><span className="d-none d-md-inline"> Imagens</span></div>}>
                    <Accordion defaultActiveKey={0} activeKey={this.state.activeKey} onSelect={activeKey => this.setState({ activeKey })} className="my-3">
                        <ImageLoader className="mb-3" multiple onChange={anexos => this.addAnexos(anexos)} title={<span><FontAwesomeIcon icon={faCameraAlt} /> Inserir Imagem</span>} />
                        {Anexos.map((anexo, key) =>
                            <Accordion.Item key={key} eventKey={key}>
                                <Accordion.Header>
                                    {anexo.nome}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row className="d-flex align-items-stretch">
                                        <Col md={6} className="d-flex flex-column align-items-stretch justify-content-center">
                                            <ImageWithTag
                                                blob={anexo.blob}
                                                onChangeBlob={blob => this.onChangeBlob(anexo, blob)}
                                                onChangeFile={file => this.onChangeFile(anexo, file)}
                                                lines={[
                                                    (anexo.latitude && anexo.longitude) ? `Coord.: ${anexo.latitude}, ${anexo.longitude}` : "",
                                                    anexo.datahora?.format("DD/MM/YYYY HH:mm") ?? "",
                                                    anexo.descricao ?? ""
                                                ]} />
                                        </Col>
                                        <Col md={6} className="d-flex flex-column">
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Group className="mb-2">
                                                        <Form.Label>Legenda</Form.Label>
                                                        <Form.Control value={anexo.descricao ?? ""} onChange={e => this.setState(state => ({ Anexos: state.Anexos.map(a => a === anexo ? { ...anexo, descricao: e.target.value } : a) }))} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="mb-2">
                                                        <Form.Label>Data/Hora</Form.Label>
                                                        <Form.Control type="datetime-local" value={anexo.datahora?.format("YYYY-MM-DDTHH:mm") ?? moment().format("YYYY-MM-DDTHH:mm")} onChange={e => this.setState(state => ({ Anexos: state.Anexos.map(a => a === anexo ? { ...anexo, datahora: moment(e.target.value) } : a) }))} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Label>Coordenadas</Form.Label>
                                                    <InputGroup className="mb-2">
                                                        <Form.Control type="number" value={anexo.latitude ?? ""} onChange={e => this.setState(state => ({ Anexos: state.Anexos.map(a => a === anexo ? { ...anexo, latitude: e.target.value } : a) }))} />
                                                        <Form.Control type="number" value={anexo.longitude ?? ""} onChange={e => this.setState(state => ({ Anexos: state.Anexos.map(a => a === anexo ? { ...anexo, longitude: e.target.value } : a) }))} />
                                                        <Button disabled={this.state.locating} variant="info" onClick={() => this.setLatLng(anexo)}>
                                                            {!this.state.locating ?
                                                                <FontAwesomeIcon icon={faLocationDot} /> :
                                                                <FontAwesomeIcon icon={faSpinner} spin />}
                                                        </Button>

                                                    </InputGroup>
                                                </Col>

                                            </Row>
                                            <Row className="mt-auto">
                                                <Col>
                                                    <Button variant="danger" className="form-control" onClick={() => window.confirm("Deseja realmente excluir esta foto?") && this.delAnexo(anexo)}>
                                                        <FontAwesomeIcon icon={faTrash} /> Excluir
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>)}
                    </Accordion>
                    <Row className="mt-3">
                        <Col md="3" xs="4">
                            <Button variant="secondary" className="w-100" onClick={() => this.context.setContent(<SitioList />)}>
                                <FontAwesomeIcon icon={faTimes} />
                                <span className="d-none d-md-inline"> Cancelar</span>
                            </Button>
                        </Col>
                        <Col md={{ offset: 3, span: 3 }} xs="4">
                            <Button variant="info" className="w-100" onClick={() => this.setState({ tabKey: "Laboratório" }, () => this.context.scrollToTop())}>
                                <FontAwesomeIcon icon={faFastBackward} /><span className="d-none d-md-inline"> Voltar</span>
                            </Button>
                        </Col>
                        <Col md="3" xs="4">
                            <Button variant="info" className="w-100" onClick={() => this.setState({ tabKey: "Observações" }, () => this.context.scrollToTop())}>
                                <span className="d-none d-md-inline">Avançar </span><FontAwesomeIcon icon={faFastForward} />
                            </Button>
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="Observações" title={<div title="Observações"><FontAwesomeIcon icon={faPencil} /><span className="d-none d-md-inline"> Observações</span></div>}>
                    <Row className="mt-3">
                        <Col md={12}><h4 className="text-light">Anotações</h4></Col>
                        <Col md={6}>
                            <Form.Group className="mb-2">
                                <Form.Label>Documentações Produzidas</Form.Label>
                                <Form.Control as="textarea" value={documentacao} onChange={e => this.setState({ documentacao: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-2">
                                <Form.Label>Bibliografia</Form.Label>
                                <Form.Control as="textarea" value={bibliografia} onChange={e => this.setState({ bibliografia: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={8}>
                            <Form.Group className="mb-2">
                                <Form.Label>Observações</Form.Label>
                                <Form.Control as="textarea" value={observacoes} onChange={e => this.setState({ observacoes: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-2">
                                <Form.Label>Atualizações</Form.Label>
                                <Form.Control as="textarea" value={atualizacoes} onChange={e => this.setState({ atualizacoes: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={12}><hr /><h4 className="text-light">Instituição de Registro</h4></Col>
                        <Col md={8}>
                            <Form.Group className="mb-2">
                                <Form.Label>Nome da Instituição</Form.Label>
                                <Form.Control value={nome_instituicao} onChange={e => this.setState({ nome_instituicao: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-2">
                                <Form.Label>Contato da Instituição</Form.Label>
                                <Form.Control value={contato_instituicao} onChange={e => this.setState({ contato_instituicao: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-2">
                                <Form.Label>Endereço da Instituição</Form.Label>
                                <Form.Control as="textarea" value={endereco_instituicao} onChange={e => this.setState({ endereco_instituicao: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-2">
                                <Form.Label>CEP da Instituição</Form.Label>
                                <Form.Control value={cep_instituicao} onChange={e => this.setState({ cep_instituicao: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={8}>
                            <Form.Group className="mb-2">
                                <Form.Label>Email da Instituição</Form.Label>
                                <Form.Control type="email" value={email_instituicao} onChange={e => this.setState({ email_instituicao: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={12}><hr /><h4 className="text-light">Responsável pelo Registro</h4></Col>
                        <Col md={8}>
                            <Form.Group className="mb-2">
                                <Form.Label>Nome do Responsável</Form.Label>
                                <Form.Control value={nome_responsavel} onChange={e => this.setState({ nome_responsavel: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-2">
                                <Form.Label>Contato do Responsável</Form.Label>
                                <Form.Control value={contato_responsavel} onChange={e => this.setState({ contato_responsavel: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-2">
                                <Form.Label>Endereço do Responsável</Form.Label>
                                <Form.Control as="textarea" value={endereco_responsavel} onChange={e => this.setState({ endereco_responsavel: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-2">
                                <Form.Label>CEP do Responsável</Form.Label>
                                <Form.Control value={cep_responsavel} onChange={e => this.setState({ cep_responsavel: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={8}>
                            <Form.Group className="mb-2">
                                <Form.Label>Email do Responsável</Form.Label>
                                <Form.Control type="email" value={email_responsavel} onChange={e => this.setState({ email_responsavel: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={8}>
                            <Form.Group className="mb-2">
                                <Form.Label>Nome da Equipe</Form.Label>
                                <Form.Control value={nome_equipe} onChange={e => this.setState({ nome_equipe: e.target.value })} />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="mb-2">
                                <Form.Label className={datahora ? '' : ''}>Data/Hora</Form.Label>
                                <Form.Control type="datetime-local" value={datahora.format("YYYY-MM-DDTHH:mm")} onChange={e => this.setState({ datahora: moment(e.target.value) })} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md="3" xs="4">
                            <Button variant="secondary" className="w-100" onClick={() => this.context.setContent(<SitioList />)}>
                                <FontAwesomeIcon icon={faTimes} />
                                <span className="d-none d-md-inline"> Cancelar</span>
                            </Button>
                        </Col>
                        <Col md={{ offset: 3, span: 3 }} xs="4">
                            <Button variant="info" className="w-100" onClick={() => this.setState({ tabKey: "Imagens" }, () => this.context.scrollToTop())}>
                                <FontAwesomeIcon icon={faFastBackward} /><span className="d-none d-md-inline"> Voltar</span>
                            </Button>
                        </Col>
                        <Col md="3" xs="4">
                            <Button type="submit" variant="primary" className="w-100">
                                <FontAwesomeIcon icon={faSave} /><span className="d-none d-md-inline">&nbsp;Salvar Rascunho</span>
                            </Button>
                        </Col>
                    </Row>
                </Tab>
            </Tabs >
        </Form >
    }
}

export default SitioForm;