import React, { useEffect } from 'react';
import { MapContainer, TileLayer, useMap, useMapEvents } from 'react-leaflet';
import AppContext from './AppContext';


const MoveHandler = ({ onMapChange, mutable, zoom }) => {

    const onChange = event => {
        const bounds = event.target.getBounds();
        onMapChange({
            minLat: bounds._southWest.lat,
            maxLat: bounds._northEast.lat,
            minLng: bounds._southWest.lng,
            maxLng: bounds._northEast.lng,
        });
    }

    useMapEvents({  
        zoom: onChange,
        moveend: onChange
    });

    const map = useMap();

    useEffect(() => {
        map.invalidateSize();
    }, [map, mutable]);

    useEffect(() => {
        map.setZoom(zoom);
    }, [map, zoom])

}

class MapWithMoveCallback extends React.Component {

    static contextType = AppContext;

    state = {
        position: [
            ((this.props.bounds?.minLat ?? 0) + (this.props.bounds?.maxLat ?? this.props.latitude)) / 2,
            ((this.props.bounds?.minLng ?? 0) + (this.props.bounds?.maxLng ?? 0)) / 2
        ]
    }

    render() {
        return (
            <MapContainer zoomControl={false} scrollWheelZoom={false} center={this.state.position} zoom={this.props.zoom} style={{ width: '100%', height: '400px' }} >
                <TileLayer url="https://a.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <MoveHandler mutable={this.props.mutable} zoom={this.props.zoom} onMove={position => this.setState({ position })} onMapChange={bounds => this.props.onMapChange(bounds)} />
            </MapContainer>
        );
    }
};

export default MapWithMoveCallback;
