import React from 'react';
import AppContext from './AppContext';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons';

class ForgotPasswordForm extends React.Component {
    static contextType = AppContext;
    state = {
        email: ""
    }

    submit(event) {
        event.preventDefault();
        const { email } = this.state;
        this.context.recover(email).finally(() => this.context.closeModal());
    }

    render() {
        return <Form onSubmit={(event) => this.submit(event)}>
            Digite seu email abaixo para receber uma mensagem com o link para recuperação de senha.
            <InputGroup className="my-3">
                <InputGroup.Text><FontAwesomeIcon icon={faEnvelope} /></InputGroup.Text>
                <Form.Control placeholder='Email' required type="text" value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
            </InputGroup>
            <div className='d-flex'>
                <Button type="submit" className='flex-grow-1 mx-2' variant="secondary" onClick={() => this.context.closeModal()}>
                    <FontAwesomeIcon icon={faTimes} /> &nbsp;
                    Cancelar
                </Button>
                <Button type="submit" className='flex-grow-1 mx-2' variant="success">
                    <FontAwesomeIcon icon={faPaperPlane} /> &nbsp;
                    Enviar
                </Button>
            </div>
        </Form>;

    }
}

export default ForgotPasswordForm;
