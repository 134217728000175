import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import reportWebVitals from './reportWebVitals';
import ContextProvider from './components/ContextProvider';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/spacelab.css';
import './styles/main.css';
import 'leaflet/dist/leaflet.css';
import 'rc-slider/assets/index.css';

navigator.serviceWorker.onmessage = (event) => {
  if (event.data.type === 'log') {
    console.log(event.data.message);
  }
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ContextProvider>
      <App />
    </ContextProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

